var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.surveyRedesign ? _c(_setup.NLayout, [_c(_setup.NCustomTemplateWithLeftBlock, {
    attrs: {
      "aside-width": "380px"
    }
  })], 1) : _c(_setup.LayoutWithLeftBlockMenu);
};
var staticRenderFns = [];
export { render, staticRenderFns };
const meetingsApi = {
  meetings: {
    list: '/api/meetings',
    statuses: '/api/meetings/statuses',
    slots: '/api/meetings/slots',
    meeting: (meetingId: number | string) => `/api/meetings/${ meetingId }`,
    refuse: (meetingId: number | string) => `/api/meetings/${ meetingId }/refuse`,
  },
};

export default meetingsApi;

export const meetingsApiConfig = meetingsApi.meetings;

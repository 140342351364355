import { defineComponent } from 'vue';
import UnauthorizedLayout from '@/components/layout/UnauthorizedLayout.vue';
export default defineComponent({
  name: 'LegalDocumentsLayout',
  components: {
    UnauthorizedLayout: UnauthorizedLayout
  },
  setup: function setup() {
    return {};
  }
});
const onboardingApi = {
  onboarding: {
    onboarding: '/api/notifications/onboarding',
    onboardingById: (onboardingId: number) => `/api/notifications/onboarding/${ onboardingId }`,
  },
};

export default onboardingApi;

export const onboardingApiConfig = onboardingApi.onboarding;

import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { useFeatureFlagsStore } from '@/store/featureFlags';

export default defineNuxtMiddleware(async ({ route, from }) => {
  const fromPath = from?.path;
  const toPath = route?.path;

  if (fromPath === toPath) {
    return;
  }

  const {
    getFlags,
  } = useFeatureFlagsStore();
  await getFlags();
});

import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.HeaderWithoutLogin
  }, [_c('div', {
    class: _vm.$style.left
  }, [_vm.isBackLinkActive ? _c('a', {
    class: _vm.$style.link,
    attrs: {
      "href": _vm.link
    }
  }, [_c('SButton', {
    class: _vm.$style.btn,
    attrs: {
      "equal": "",
      "theme": "ghost",
      "size": "small",
      "only-visual": ""
    }
  }, [_c('SIcon', {
    attrs: {
      "icon": "i-arrowLeft",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.linkText
  }, [_vm._v("\n        Вернуться на главный сайт\n      ")])], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    class: _vm.$style.right
  }, [_c('client-only', [_c('HoverableLink', {
    class: _vm.$style.hoverableLink,
    attrs: {
      "href": "tel:".concat(_vm.phone),
      "text": _vm.preparedPhone,
      "font-size": "14px"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('SButton', {
          class: _vm.$style.phoneButton,
          attrs: {
            "equal": "",
            "size": "custom",
            "theme": "ghost",
            "only-visual": ""
          }
        }, [_c('SIcon', {
          attrs: {
            "icon": "i-phone",
            "size": "24"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _vm.$slots.fired ? _c('div', {
    class: _vm.$style.userButton
  }, [_vm._t("fired")], 2) : _vm._e(), _vm._v(" "), _vm.isLoginButtonActive ? _c('SButton', {
    class: [_vm.$style.button, _vm.$style.hoverBase],
    attrs: {
      "n-link": "",
      "href": "/login",
      "rounded": "",
      "size": "x-small",
      "theme": "primary"
    }
  }, [_vm._v("\n      Войти / Зарегистрироваться\n    ")]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
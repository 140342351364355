// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".template_FbTtU{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:calc(100vh - var(--header-h))}@media only screen and (max-width:1023.98px){.template_FbTtU{min-height:calc(100vh - 9.5rem)}}.main_QWsr4{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;-webkit-box-flex:1;-ms-flex:1;flex:1;padding-left:4.4rem}@media only screen and (max-width:1023.98px){.main_QWsr4{padding-left:0}}.main_QWsr4.fixedContentWidth_VuCd8{margin-left:auto;margin-right:auto;max-width:calc(var(--container-width) + var(--gutter)*2);padding-left:var(--gutter);padding-right:var(--gutter);width:100%}.main_QWsr4.fixedContentWidth_VuCd8 .aside_rSy8E{padding-left:0}.main_QWsr4 .content_l-wtf{min-width:0;width:100%}@media only screen and (max-width:1023.98px){.main_QWsr4 .content_l-wtf{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}}.main_QWsr4 .aside_rSy8E{min-height:calc(100vh - var(--header-h));min-width:var(--aside);position:sticky;width:var(--aside)}@media only screen and (max-width:1023.98px){.main_QWsr4 .aside_rSy8E{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": "template_FbTtU",
	"main": "main_QWsr4",
	"fixedContentWidth": "fixedContentWidth_VuCd8",
	"aside": "aside_rSy8E",
	"content": "content_l-wtf"
};
module.exports = ___CSS_LOADER_EXPORT___;

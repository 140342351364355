import type {
  Role,
  Strategy,
} from '@/store/authStore';

const accountApi = {
  account: {
    setPassword: (strategy: Strategy) => `/api/${ strategy }/set_password`,
    /** @deprecated для agents */
    resetAvailable: (strategy: Strategy) => `/api/${ strategy }/reset_available`,
    /** @deprecated используйте V2 */
    changePassword: (strategy: Strategy) => `/api/${ strategy }/change_password`,
    changePasswordV2: (strategy: Strategy) => `/api/v2/${ strategy }/change_password`,
    resetPassword: '/api/users/reset_password',
    getCautions: '/api/cautions',
    deleteCaution: (id: string | number) => `/api/cautions/${ id }`,
    register: (strategy: Strategy) => `/api/${ strategy }/register`,
    registerV2: (strategy: Strategy) => `/api/v2/${ strategy }/register`,
    checkContacts: '/api/users/check_user_contacts',
    joinAgency: (strategy: Strategy) => `/api/${ strategy }/join_agency`,
    consultationTypes: '/api/users/consultation_type',
    profile: (strategy: Strategy) => `/api/${ strategy }/profile`,
    emailReset: (strategy: Strategy) => `/api/${ strategy }/email_reset`,
    partialUpdate: '/api/users/partial_update',
    changePhone: '/api/users/change_phone',

    documents: {
      acts: (role: Role) => `/api/agencies/${ role }/acts`,
      act: (role: Role, id: string | number) => `/api/agencies/${ role }/acts/${ id }`,
    },

    agencies: {
      profile: '/api/agencies/profile',
      profileByStrategy: (strategy: Strategy) => `/api/agencies/${ strategy }/profile`,
      agencyExists: (agencyInn: string | number) => `/api/agencies/${ agencyInn }/exists`,
      agencyRetrieve: (agencyInn: string | number) => `/api/agencies/${ agencyInn }`,
      agencyGetContractData: (role: Role) => `/api/agencies/${ role }`,
      agencyFillContractData: (role: Role) => `/api/agencies/${ role }/fill`,
      getAgreementTypes: '/api/agencies/repres/agreements/types',
      agreements: (role: Role) => `/api/agencies/${ role }/agreements`,
      agreement: (role: Role, id: string | number) => `/api/agencies/${ role }/agreements/${ id }`,
    },

    managers: {
      getManager: (city: string) => `/api/managers?city=${ city }`,
    },

    clients: {
      specs: (role: Role) => `/api/users/${ role }/clients/specs`,
      facets: (role: Role) => `/api/users/${ role }/clients/facets`,
      lookup: (role: Role) => `/api/users/${ role }/clients/lookup`,
    },

    bookings: {
      specs: (role: Role) => `/api/users/${ role }/bookings/specs`,
      specsV2: (role: Role) => `/api/v2/users/${ role }/bookings/specs`,
      facets: (role: Role) => `/api/users/${ role }/bookings/facets`,
      lookup: (role: Role) => `/api/users/${ role }/bookings/lookup`,
    },

    dependentRole: {
      // Когда все роли перейдут на v2 убрать лишний эндпоинт.
      list: (dependentRole: string, strategy: Strategy) => `/api/${ dependentRole }/${ strategy }`,
      clients: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/clients`,
      specs: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/specs`,
      facets: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/facets`,
      lookup: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/lookup`,
      clientDetail: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/clients/${ id }`,
      detail: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/${ id }`,
      check: (dependentRole: string, role: Role) => `/api/v2/${ dependentRole }/${ role }/check`,
      checkDispute: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/check-dispute`,
      approval: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/approval/${ id }`,
      register: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/register`,
      rebound: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/rebound/${ id }`,
      bound: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/bound/${ id }`,
      unbound: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/unbound/${ id }`,
      bookings: (dependentRole: string, role: Role) => `/api/${ dependentRole }/${ role }/bookings`,
      booking: (dependentRole: string, role: Role, id: string | number) => `/api/${ dependentRole }/${ role }/bookings/${ id }`,
      uploadDocuments: (strategy: Strategy, bookingId: string | number, id: string | number) => `/api/${ strategy }/bookings/${ bookingId }/upload-documents/${ id }`,
    },
  },
};

export default accountApi;

export const accountApiConfig = accountApi.account;

const propertiesApi = {
  properties: {
    types: '/api/properties/types',
    prices: '/api/properties/prices',
    typeSpecs: '/api/properties/type_specs',
    like: '/api/properties/article/like',
    likeById: (profitbaseId: number) => `/api/properties/article/like/${ profitbaseId }`,
  },
};

export default propertiesApi;

export const propertiesApiConfig = propertiesApi.properties;

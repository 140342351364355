import "core-js/modules/esnext.array.last-item.js";
var render = function render() {
  var _setup$user$loyalty, _setup$user$loyalty2, _setup$user$loyalty3, _setup$user$loyalty4;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.HeaderProfileDropdown
  }, [_c('ul', [_c('li', {
    class: _vm.$style.listItem
  }, [_c('NuxtLink', {
    class: [_vm.$style.listItemContent, _vm.$style.listItemContentClickable],
    attrs: {
      "to": "/profile"
    }
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "i-user",
      "size": "24"
    }
  }), _vm._v("\n        Профили\n      ")], 1)], 1), _vm._v(" "), _setup.user && !_setup.isAggregator ? _c('li', {
    class: _vm.$style.listItem
  }, [_c('NuxtLink', {
    class: [_vm.$style.listItemContent, _vm.$style.listItemContentClickable],
    attrs: {
      "to": "/loyalty-program"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.emit('dropdown-item-click');
      }
    }
  }, [(_setup$user$loyalty = _setup.user.loyalty) !== null && _setup$user$loyalty !== void 0 && (_setup$user$loyalty = _setup$user$loyalty.levelIcon) !== null && _setup$user$loyalty !== void 0 && _setup$user$loyalty.aws ? _c('img', {
    class: _vm.$style.customIcon,
    attrs: {
      "src": _setup.user.loyalty.levelIcon.aws,
      "alt": ""
    }
  }) : _c(_setup.SIcon, {
    attrs: {
      "icon": "i-crownOutline",
      "size": "24"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.inlineVertical
  }, [_c('span', [_vm._v("Программа лояльности")]), _vm._v(" "), _c('span', {
    class: _vm.$style.loyaltyLevelLabel
  }, [_vm._v(_vm._s((_setup$user$loyalty2 = _setup.user.loyalty) === null || _setup$user$loyalty2 === void 0 ? void 0 : _setup$user$loyalty2.label))])]), _vm._v(" "), (_setup$user$loyalty3 = _setup.user.loyalty) !== null && _setup$user$loyalty3 !== void 0 && _setup$user$loyalty3.point ? _c('ScoreLabel', {
    attrs: {
      "value": _setup.user.loyalty.point,
      "size": "small"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _setup.user && !_setup.isAggregator && _setup.isShowDayDowngradeStatus ? _c('li', {
    class: _vm.$style.listItem
  }, [_c('div', {
    class: _vm.$style.listItemContent
  }, [_c('div', {
    class: _vm.$style.customIcon
  }), _vm._v(" "), _c(_setup.StatusDowngradeTimer, {
    attrs: {
      "date": (_setup$user$loyalty4 = _setup.user.loyalty) === null || _setup$user$loyalty4 === void 0 ? void 0 : _setup$user$loyalty4.date_downgrade_status
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), _c('hr'), _vm._v(" "), _c('button', {
    class: [_vm.$style.listItemContent, _vm.$style.listItemContentClickable, _vm.$style.lastItem],
    on: {
      "click": _setup.onLogoutClick
    }
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "i-logout",
      "size": "24"
    }
  }), _vm._v("\n    Выйти\n  ")], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { defineStore } from 'pinia';

import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';

import type { StateChanger } from 'vue-infinite-loading';
import { useNotify } from '@@/common/plugins/notify';
import { useUserRole } from '@@/shared/composables/useUserRole';
import type {
  ContractData,
  Project,
  Type,
} from '@/types/Contract';
import type { Agency } from '@/types/Agencies';
import type { PageInfo } from '@/types/PageInfo';
import type {
  Agreement,
  PreparedAgreement,
} from '@/types/Agreements';

interface AgreementsResponse {
  count: number;
  page_info: PageInfo;
  result: Array<Agreement>;
}

interface Step {
  component: string;
  label: string;
}

const defaultSteps: Array<Step> = [
  {
    component: 'CreateContract',
    label: 'Данные об организации',
  },
  {
    component: 'ChooseComplex',
    label: 'Выбор ЖК',
  },
  {
    component: 'ChooseType',
    label: 'Выбор типа договора',
  },
  {
    component: 'ContractList',
    label: 'Договоры',
  },
];

async function notifyError (error: unknown, fallbackMessage?: string): Promise<void> {
  const notifier = useNotify();

  if (!notifier) {
    return;
  }

  const message = getErrorMessageTyped(error, fallbackMessage);

  await notifier.create({
    message,
    type: 'negative',
  });

  throw new Error(message);
}

interface State {
  steps: Array<Step>;
  contractData: ContractData;
  currentStep?: Step;
  currentComplex: Array<number>;
  types: Array<Type>;
  currentType?: Type;
  currentAgreement?: Agreement;
  agreements: Array<PreparedAgreement>;
  newContracts: Array<PreparedAgreement>;
  count: number;
  pageInfo: PageInfo | object;
}

export const useContractStore = defineStore('contract', {
  state: (): State => ({
    steps: defaultSteps,
    contractData: {
      isInformationFilled: false,
      isComplexChosen: false,
      isTypeChosen: false,
    },
    currentStep: defaultSteps[0],
    currentComplex: [],
    types: [],
    currentType: undefined,
    currentAgreement: undefined,
    agreements: [],
    newContracts: [],
    count: 0,
    pageInfo: {},
  }),

  actions: {
    async getOrganisationData (): Promise<object | undefined> {
      const fallbackMessage = 'Не удалось получить данные организации';

      try {
        const role = useUserRole();
        const {
          agencyGetContractData,
        } = this.$api.account.agencies;
        const url = agencyGetContractData(role);

        const {
          data,
        } = await this.$axios.get<Agency>(url);

        return data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ getOrganisationData ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error, fallbackMessage);
      }
    },

    async updateOrganisationData (contractData: object): Promise<void> {
      try {
        const role = useUserRole();
        const {
          agencyFillContractData,
        } = this.$api.account.agencies;
        const url = agencyFillContractData(role);

        await this.$axios.patch(url, contractData);
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ updateOrganisationData ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error);
      }
    },

    setComplex (payload: Array<Project>): void {
      this.currentComplex = payload
        .filter(({ active }) => active)
        .map(({ id }) => id);
    },

    async getTypes (): Promise<void> {
      try {
        const url = this.$api.account.agencies.getAgreementTypes;
        const {
          data,
        } = await this.$axios.get<Array<Type>>(url);

        this.types = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ getTypes ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    setType (payload: Type): void {
      this.currentType = payload;
    },

    async getAgreements (params: object): Promise<void> {
      try {
        const role = useUserRole();
        const {
          agreements,
        } = this.$api.account.agencies;
        const url = agreements(role);
        const {
          data: {
            result,
            count,
            page_info: pageInfo,
          },
        } = await this.$axios.get<AgreementsResponse>(url, {
          params,
        });

        this.agreements = preparedContracts(result);
        this.count = count;
        this.pageInfo = pageInfo;
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ getAgreements ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState,
      }: {
        page: PageInfo;
        infiniteState: StateChanger;
      }): Promise<void> {
      try {
        if (!page.next_page) {
          return;
        }

        const {
          data: {
            result,
            page_info: pageInfo,
          },
        } = await this.$axios.get<AgreementsResponse>(page.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          this.agreements = [
            ...this.agreements,
            ...preparedContracts(result),
          ];

          if (!pageInfo.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    },

    async createAgreements (): Promise<void> {
      try {
        const role = useUserRole();
        const {
          agreements,
        } = this.$api.account.agencies;
        const url = agreements(role);
        const body = {
          projects: this.currentComplex,
          type_id: this.currentType?.id,
        };
        const {
          data,
        } = await this.$axios.post<Array<Agreement>>(url, body);

        this.newContracts = preparedContracts(data);
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ createAgreements ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error);
      }
    },

    async getAgreement (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const {
          agreement: agreementUrl,
        } = this.$api.account.agencies;
        const url = agreementUrl(role, id);
        const {
          data,
        } = await this.$axios.get<Agreement>(url);

        this.currentAgreement = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ getAgreement ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    setCurrentStep (payload: string): void {
      this.currentStep = this.steps.find(
        ({ component }) => component === payload,
      );
    },

    async getAgreementDocument (id: number): Promise<void> {
      let timer: ReturnType<typeof setTimeout>;
      const fallbackMessage = 'Не удалось получить документ';

      try {
        const role = useUserRole();
        const {
          getDocument,
        } = this.$api.documents;
        const url = getDocument(role, id);
        const {
          data,
        } = await this.$axios.get<Agreement>(url);
        const link = data.files?.[0]?.files?.[0]?.aws;

        if (!link) {
          throw new Error(fallbackMessage);
        }

        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        document.body.appendChild(a);
        // FIXME если сразу открывать ссылки, то иногда падает ошибка strana_lk-1379
        // добавлен таймаут
        timer = setTimeout(() => {
          a.click();
          document.body.removeChild(a);
          clearTimeout(timer);
        }, 500);
      } catch (error: unknown) {
        console.log('🚀 ~ file: contract.ts ~ getAgreementDocument ~ error', error);
        this.$sentry.captureException(error);

        await notifyError(error, fallbackMessage);
      }
    },
  },
});

function preparedContracts (items: Array<Agreement>): Array<PreparedAgreement> {
  return items.map((item) => {
    const {
      slug,
      files,
    } = item.files[0] ?? {};

    return {
      ...item,
      documentType: slug,
      files,
    };
  });
}

import type { Role } from '@/store/authStore';

const usersApi = {
  users: {
    dispute: (role: Role, userId: string) => `/api/users/${ role }/dispute/${ userId }`,
    pin: (role: Role) => `/api/users/${ role }/assign_client`,
  },
};

export default usersApi;

export const usersApiConfig = usersApi.users;

import { defineStore } from 'pinia';
import { onboardingApiConfig } from '@/config/api/onboarding';

interface NotificationRaw {
  id: number;
  message: string;
  slug: string;

  button_text: string;
}

interface Notification {
  id: number;
  message: string;
  slug: string;
  buttonText: string;
}

interface State {
  notifications: Array<Notification> | undefined;
}

export const useOnboardingStore = defineStore('onboarding', {
  state: (): State => ({
    notifications: undefined,
  }),

  getters: {},

  actions: {
    async getNotifications (): Promise<void> {
      try {
        const url = onboardingApiConfig.onboarding;
        const { data } = await this.$axios.get<Array<NotificationRaw>>(url);

        this.notifications = data?.map(({
          id,
          message,
          slug,
          button_text: buttonText,
        }: NotificationRaw) => ({
          id,
          message,
          slug,
          buttonText,
        }));
      } catch (error) {
        console.error('🚀 ~ file: onboarding.ts ~ getNotifications ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async confirmNotification (id: number): Promise<void> {
      try {
        const url = onboardingApiConfig.onboardingById(id);
        await this.$axios.patch(url);

        this.notifications = this.notifications?.filter(item => item.id !== id);
      } catch (error) {
        console.error('🚀 ~ file: onboarding.ts ~ confirmNotification ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },
  },
});

import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { userNameWithInitialsFormatted } from '@@/common/utils/text-utils';
import { SButton, SIcon, SModal } from '@strana-artw/ui-kit';
import { pageScrollService } from '@@/shared/services/PageScrollService';
export default {
  name: 'TheContextMenu',
  components: {
    SModal: SModal,
    VMenu: function VMenu() {
      return import('@@/common/components/ui/VMenu');
    },
    SIcon: SIcon,
    SButton: SButton
  },
  data: function data() {
    return {
      show: false,
      isMounted: false
    };
  },
  computed: {
    items: function items() {
      return this.$contextMenu.data.items;
    },
    data: function data() {
      return this.$contextMenu.data;
    },
    headingName: function headingName() {
      var _this$data$item, _this$data;
      var _ref = (_this$data$item = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.item) !== null && _this$data$item !== void 0 ? _this$data$item : {},
        surname = _ref.surname,
        name = _ref.name,
        patronymic = _ref.patronymic;
      if (!surname || !name || !patronymic) {
        return '';
      }
      return userNameWithInitialsFormatted(surname, name, patronymic);
    },
    isMobile: function isMobile() {
      var _window;
      if (!process.client) {
        return false;
      }
      return (_window = window) === null || _window === void 0 || (_window = _window.$nuxt) === null || _window === void 0 ? void 0 : _window.$breakpoint.mdAndDown;
    },
    component: function component() {
      if (!this.isMobile) {
        return {
          is: 'VMenu',
          bind: !this.data.options.attach ? {
            'position-x': this.data.options.x,
            'position-y': this.data.options.y,
            'absolute': true,
            'offset-y': true,
            'content-class': 'contextMenu'
          } : {
            'attach': this.data.options.attach,
            'content-class': 'contextMenu contextMenuAttached'
          }
        };
      }
      return {
        is: 'SModal',
        bind: {
          type: 'bottom-popup',
          theme: 'white',
          typeOptions: {
            isContentHeight: true
          }
        }
      };
    },
    isShow: {
      get: function get() {
        var _this$$contextMenu;
        return this === null || this === void 0 || (_this$$contextMenu = this.$contextMenu) === null || _this$$contextMenu === void 0 || (_this$$contextMenu = _this$$contextMenu.data) === null || _this$$contextMenu === void 0 ? void 0 : _this$$contextMenu.show;
      },
      set: function set(val) {
        this.show = val;
        this.onChange('show', val);
        if (!val) {
          this.$emit('close');
          pageScrollService.unlock();
        }
      }
    }
  },
  mounted: function mounted() {
    // FIXME если не делать этот костыль, страница deals (и наверняка много других)
    // грохается из-за ошибок, связанных с hydratation-mismatch
    // вероятно, будет решено, когда будет заменён VMenu
    this.isMounted = true;
  },
  beforeMount: function beforeMount() {
    this.$contextMenu.event.$on('open', this.onOpen);
    this.$contextMenu.event.$on('close', this.onClose);
    this.$contextMenu.event.$on('change', this.onChange);
  },
  beforeDestroy: function beforeDestroy() {
    this.$contextMenu.event.$off('open', this.onOpen);
    this.$contextMenu.event.$off('close', this.onClose);
    this.$contextMenu.event.$off('change', this.onChange);
  },
  methods: {
    handleClose: function handleClose() {
      this.isShow = false;
      pageScrollService.unlock();
    },
    onOpen: function onOpen(_ref2) {
      var items = _ref2.items,
        heading = _ref2.heading,
        options = _ref2.options,
        item = _ref2.item;
      // console.log('🚀 ~ file: TheContextMenu.vue ~ line 131 ~ onOpen ~ tems, heading, options, item', items, heading, options, item);
      this.$contextMenu.change('heading', heading);
      this.$contextMenu.change('options', options);
      this.$contextMenu.change('item', item);
      this.$contextMenu.change('items', items);
      this.$contextMenu.change('show', true);
      this.isShow = true;
      if (items) {
        var _iterator = _createForOfIteratorHelper(items),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var it = _step.value;
            if (!it.value || !it.callback) {
              continue;
            }
            this.$on(it.value, it.callback);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    },
    onClose: function onClose() {
      var _this$$contextMenu2;
      if (this !== null && this !== void 0 && (_this$$contextMenu2 = this.$contextMenu) !== null && _this$$contextMenu2 !== void 0 && (_this$$contextMenu2 = _this$$contextMenu2.data) !== null && _this$$contextMenu2 !== void 0 && _this$$contextMenu2.items) {
        var _iterator2 = _createForOfIteratorHelper(this.$contextMenu.data.items),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var it = _step2.value;
            if (!it.value || !it.callback) {
              continue;
            }
            this.$off(it.value, it.callback);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      this.$contextMenu.change('heading', '');
      this.$contextMenu.change('options', {});
      this.$contextMenu.change('item', {});
      this.$contextMenu.change('items', []);
      this.$contextMenu.change('show', false);
      this.isShow = false;
    },
    onChange: function onChange(prop, val) {
      this.$set(this.data, prop, val);
    }
  }
};
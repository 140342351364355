// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusDowngradeTimer_lkZLh{background:#f0f1f2;border-radius:.4rem;display:-webkit-box;display:-ms-flexbox;display:flex;padding:.4rem .8rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#ed1c24;font-family:Mazzard,sans-serif;font-size:1.2rem;font-style:normal;font-weight:500;gap:.4rem;line-height:130%;min-height:3.2rem}.timer_wdpfB{min-width:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatusDowngradeTimer": "StatusDowngradeTimer_lkZLh",
	"timer": "timer_wdpfB"
};
module.exports = ___CSS_LOADER_EXPORT___;

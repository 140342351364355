import { useApiClient } from '@/plugins/axios';
import { bookingApiConfig } from '@/config/api/booking';

export interface UserBookingRequest {
  id: number;
  amocrm_stage: string;
  amocrm_substage: string;
  project: {
    name: string;
    slug: string;
    city: string;
  };
}

/**
 *  Получение сделок клиента
 * @param userId
 */
export async function getUserBookings (userId: number): Promise<Array<UserBookingRequest>> {
  const url = bookingApiConfig.bookingByUser;

  const { data } = await useApiClient()
    .get<Array<UserBookingRequest>>(url, {
      params: {
        user_id: userId,
      },
    });

  return data;
}

import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
import { computed, defineComponent } from 'vue';
import { useRoute } from '@nuxtjs/composition-api';
import NFooter from './next/NFooter.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import { useHeaderStore } from '@/store/header';
export default defineComponent({
  name: 'UnauthorizedLayout',
  components: {
    NFooter: NFooter,
    TheFooter: TheFooter
  },
  props: {
    withLoginButton: {
      type: Boolean,
      default: true
    },
    withBackLink: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var headerStore = useHeaderStore();
    var _useHeaderStore = useHeaderStore(),
      toggleHeader = _useHeaderStore.toggleHeader;
    var route = useRoute();
    var isLegalDocumentsRoute = computed(function () {
      return route.value.path.includes('legal-documents');
    });
    return {
      headerStore: headerStore,
      toggleHeader: toggleHeader,
      isLegalDocumentsRoute: isLegalDocumentsRoute
    };
  },
  computed: {
    isOpened: function isOpened() {
      return this.headerStore.getHeaderState;
    },
    isFixed: function isFixed() {
      var _this$$route, _this$$route2;
      return ((_this$$route = this.$route) === null || _this$$route === void 0 || (_this$$route = _this$$route.name) === null || _this$$route === void 0 ? void 0 : _this$$route.includes('contract')) || ((_this$$route2 = this.$route) === null || _this$$route2 === void 0 || (_this$$route2 = _this$$route2.name) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.includes('account'));
    }
  }
});
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".managerInfo_VWaXN{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;gap:.8rem}.avatarWrapper_ZtPGG{border-radius:50%;height:4.2rem;overflow:hidden;position:relative;width:4.2rem;-ms-flex-negative:0;flex-shrink:0}.avatar_X\\+91Q{background-color:#d9d9d9;max-width:100%}.dataColumn_KBrJk{gap:.2rem}.dataColumn_KBrJk,.rightSide_u3eHI{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.rightSide_u3eHI{gap:1.6rem}.managerName_UsZZk,.phoneLink_oILwM,.scheduleValue_fKfwS{color:#fff;font-weight:400}.managerName_UsZZk{font-size:1.6rem;letter-spacing:-.01rem;line-height:2.2rem}.phoneLink_oILwM,.scheduleValue_fKfwS{font-size:1.4rem;line-height:2rem}.scheduleValue_fKfwS{letter-spacing:-.01rem}.phoneLink_oILwM{letter-spacing:.03rem}.managerPosition_ttXL5,.phoneTitle_ruBU7,.scheduleTitle_ZCM\\+9{color:#8fa6bd;font-size:1.2rem;font-weight:500;letter-spacing:-.08rem;line-height:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"managerInfo": "managerInfo_VWaXN",
	"avatarWrapper": "avatarWrapper_ZtPGG",
	"avatar": "avatar_X+91Q",
	"dataColumn": "dataColumn_KBrJk",
	"rightSide": "rightSide_u3eHI",
	"managerName": "managerName_UsZZk",
	"phoneLink": "phoneLink_oILwM",
	"scheduleValue": "scheduleValue_fKfwS",
	"managerPosition": "managerPosition_ttXL5",
	"phoneTitle": "phoneTitle_ruBU7",
	"scheduleTitle": "scheduleTitle_ZCM+9"
};
module.exports = ___CSS_LOADER_EXPORT___;

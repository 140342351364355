var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.days ? _c('div', {
    class: _vm.$style.StatusDowngradeTimer
  }, [_c(_setup.SIcon, {
    class: _vm.$style.timer,
    attrs: {
      "icon": "i-timer",
      "size": "16"
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_setup.$tc('days', _setup.days)) + " до понижения статуса")])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
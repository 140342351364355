export const roles = ['agents', 'represes'] as const;
export type Role = typeof roles[number];

export const roleToLabelMap: Record<Role, string> = {
  agents: 'Агент',
  represes: 'Агентство',
};

export const GeneralTypes = {
  agency: 'agency',
  aggregator: 'aggregator',
} as const;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuDesktopActivator_ZfyJZ{left:0;position:fixed;top:14rem}.pseudoButton_dt7t9{display:-webkit-box;display:-ms-flexbox;display:flex;height:10.7rem;position:relative;top:0;width:5.2rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#fff;overflow:hidden;padding-left:.8rem}.pseudoButton_dt7t9:before{background-color:#012345;border-radius:10rem;content:\"\";height:100%;position:absolute;right:0;width:200%}.icon_3D4G3{position:relative;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuDesktopActivator": "MenuDesktopActivator_ZfyJZ",
	"pseudoButton": "pseudoButton_dt7t9",
	"icon": "icon_3D4G3"
};
module.exports = ___CSS_LOADER_EXPORT___;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.RegistrationPolicy
  }, [_c(_setup.SCheckbox, {
    class: _vm.$style.checkbox,
    attrs: {
      "theme": "secondary"
    },
    model: {
      value: _setup.v$.form.acceptPolicy.$model,
      callback: function callback($$v) {
        _vm.$set(_setup.v$.form.acceptPolicy, "$model", $$v);
      },
      expression: "v$.form.acceptPolicy.$model"
    }
  }, [_c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n      Нажимая на кнопку я подтверждаю, что принимаю условия\n      "), _c('NuxtLink', {
    attrs: {
      "target": _vm.target,
      "to": _setup.links.accept
    }
  }, [_vm._v("\n        пользовательского соглашения\n      ")]), _vm._v("\n      и ознакомлен с\n      "), _c('NuxtLink', {
    attrs: {
      "target": _vm.target,
      "to": _setup.links.politic
    }
  }, [_vm._v("\n        политикой обработки персональных данных\n      ")])], 1)]), _vm._v(" "), _setup.isAgent || !_setup.isAggregator ? _c(_setup.SCheckbox, {
    class: _vm.$style.checkbox,
    attrs: {
      "theme": "secondary"
    },
    model: {
      value: _setup.v$.form.acceptOffer.$model,
      callback: function callback($$v) {
        _vm.$set(_setup.v$.form.acceptOffer, "$model", $$v);
      },
      expression: "v$.form.acceptOffer.$model"
    }
  }, [_c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n      Нажимая на кнопку я подтверждаю, что " + _vm._s(_setup.isAgent ? 'ознакомлен' : 'принимаю') + "\n      "), _c('NuxtLink', {
    attrs: {
      "target": _vm.target,
      "to": _setup.links.brokerOffer
    }
  }, [_vm._v("\n        " + _vm._s(_setup.isAgent ? 'с офертой агентского договора' : 'оферту агентского договора') + "\n      ")]), _vm._v("\n      и\n      "), _c('NuxtLink', {
    attrs: {
      "target": _vm.target,
      "to": _setup.links.brokerLoyaltyProgram
    }
  }, [_vm._v("\n        " + _vm._s(_setup.isAgent ? 'публичной офертой' : 'публичную оферту') + " на участие в программе лояльности \"Страна.Брокер\"\n      ")])], 1)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import type { Role } from '@/store/authStore';

const documentsApi = {
  documents: {
    detail: (documentSlug: string) => `/api/documents/slug/${ documentSlug }`,
    getQuizDocuments: (slug: string, bookingId: number) => `/api/agents/questionnaire/${ slug }/groups_of_documents/${ bookingId }`,
    sendPackageDocuments: (bookingId: number) => `/api/agents/questionnaire/${ bookingId }/send`,
    uploadDocuments: () => '/api/v2/documents/upload-documents',
    deleteDocument: () => '/api/documents/delete-documents',
    getDocument: (role: Role, agreementId: number) => `/api/agencies/${ role }/agreements/${ agreementId }/get_document`,
    instructionsBySlug: (slug: string) => `/api/documents/instructions/${ slug }`,
    interactions: '/api/documents/interactions',
  },
};

export default documentsApi;

export const documentsApiConfig = documentsApi.documents;

export const CLIENT_STATUSES = {
  PROGRESS: 'progress',
  UNIQUE: 'unique',
  SAME_PINNED: 'same_pinned',
  PINNED: 'pinned'
};

export const PROJECT_STATUSES = {
  FUTURE: 'future',
  CURRENT: 'current'
};

export enum ConsultationType {
  offline = 'offline_meet',
  online = 'online_consultation'
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('UnauthorizedLayout', {
    attrs: {
      "with-login-button": false
    }
  }, [_c('Nuxt', {
    class: _vm.$style.page
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
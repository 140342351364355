import { defineStore } from 'pinia';
import { useEventStore } from './events';
import { useOnboardingStore } from '@/store/onboarding';

export const useAuthActionsStore = defineStore('authActions', () => {
  const { getNotifications } = useOnboardingStore();

  async function executeLoginActions (store: any): Promise<void> {
    if (!store.state.events.qrCodeData) {
      await useEventStore().loadQRCode();
    }

    if (process.client) {
      await getNotifications();
    }
  }

  async function executeLogoutActions (store: any): Promise<void> {
    useEventStore().resetQRCode();
  }

  return {
    executeLoginActions,
    executeLogoutActions,
  };
});

import {
  iosScrollLockedAttributeName,
  iosScrollLockedOffsetCssProp,
  iosScrollShouldLockAttributeName,
  isOldIOSAttributeName,
} from '@@/shared/constants/technical';
import type { IPageScrollService } from '../interface';

import { getBodyScrollbarWidth } from '../utils';

export class PageScrollServiceIOS implements IPageScrollService {
  private lockedMap = new Map<HTMLElement, number>();

  private lockElement (element: HTMLElement): void {
    if (element.hasAttribute(iosScrollShouldLockAttributeName)) {
      if (!element.hasAttribute(iosScrollLockedAttributeName)) {
        const scrollTop = element.scrollTop;
        element.setAttribute(iosScrollLockedAttributeName, scrollTop.toString());
        element.style.setProperty(iosScrollLockedOffsetCssProp, `${ scrollTop }px`);
      }
      const alreadyLockedTimes = this.lockedMap.get(element) ?? 0;
      this.lockedMap.set(element, alreadyLockedTimes + 1);
    }
  }

  private lockAllParents (element: HTMLElement): void {
    // лочить родителей будем в обратном порядке: от деда к правнуку - потому что в прямом порядке
    // scrollTop-ы теряются
    const parentsStraight: Array<HTMLElement> = [];
    for (let parent = element.parentElement; parent != null; parent = parent.parentElement) {
      parentsStraight.push(parent);
    }

    for (let i = parentsStraight.length - 1; i >= 0; i--) {
      this.lockElement(parentsStraight[i]);
    }

    // костыль для html/body
    // объяснить не могу, на html почему-то не действуют top: -scrollTop или margin-top: -scrollTop
    const htmlScrollTop = Number(document.documentElement.getAttribute(iosScrollLockedAttributeName));
    const bodyScrollTop = Number(document.body.getAttribute(iosScrollLockedAttributeName));

    if (htmlScrollTop > 0 && bodyScrollTop === 0) {
      document.body.setAttribute(iosScrollLockedAttributeName, htmlScrollTop.toString());
      document.body.style.setProperty(iosScrollLockedOffsetCssProp, `${ htmlScrollTop }px`);
    }
  }

  private unlockElement (element: HTMLElement): void {
    if (!this.lockedMap.has(element)) {
      return;
    }

    const alreadyLockedTimes = this.lockedMap.get(element) ?? 0;

    if (alreadyLockedTimes > 1) {
      this.lockedMap.set(element, alreadyLockedTimes - 1);
    } else {
      this.lockedMap.delete(element);
      const scrollTop = Number(element.getAttribute(iosScrollLockedAttributeName));
      element.removeAttribute(iosScrollLockedAttributeName);
      element.style.removeProperty(iosScrollLockedOffsetCssProp);

      if (!isNaN(scrollTop)) {
        element.scrollTop = scrollTop;
      }
    }
  }

  private unlockAllParents (element: HTMLElement): void {
    if (!element) {
      return;
    }

    let parent = element.parentElement;
    while (parent) {
      this.unlockElement(parent);
      parent = parent.parentElement;
    }
  }

  private forceUnlockAll (): void {
    for (const [element] of this.lockedMap) {
      element.classList.remove(iosScrollLockedAttributeName);
    }
    this.lockedMap.clear();
  }

  private setScrollOffset (offset: number): void {
    if (!process.client) {
      return;
    }

    document.documentElement.style.setProperty('--scroll-lock-offset', `${ offset }px`);
  }

  private inited = false;
  lock (allowedScrollElements?: Array<HTMLElement>): void {
    if (!this.isLocked()) {
      this.setScrollOffset(getBodyScrollbarWidth());
    }

    if (!this.inited) {
      this.inited = true;
      document.documentElement.setAttribute(isOldIOSAttributeName, '');
    }

    for (const x of allowedScrollElements ?? [document.body]) {
      this.lockAllParents(x);
    }
  }

  unlock (allowedScrollElements?: Array<HTMLElement>): void {
    for (const x of allowedScrollElements ?? [document.body]) {
      this.unlockAllParents(x);
    }

    if (!this.isLocked()) {
      this.setScrollOffset(0);
    }
  }

  forceUnlock (): void {
    this.setScrollOffset(0);

    this.forceUnlockAll();
  }

  isLocked (): boolean {
    return this.lockedMap.size > 0;
  }
}

/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./StatusDowngradeTimer.vue?vue&type=template&id=38066ad8"
import script from "./StatusDowngradeTimer.vue?vue&type=script&setup=true&lang=ts"
export * from "./StatusDowngradeTimer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StatusDowngradeTimer.vue?vue&type=style&index=0&id=38066ad8&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./StatusDowngradeTimer.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
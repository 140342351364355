const eventsApi = {
  events: {
    getEvent: (eventId: number) => `/api/events/${ eventId }`,
    accept: (eventId: number) => `/api/events/${ eventId }/accept`,
    refuse: (eventId: number) => `/api/events/${ eventId }/refuse`,
    getQRCode: '/api/events_list/qr_code/',
  },
};

export default eventsApi;

export const eventsApiConfig = eventsApi.events;

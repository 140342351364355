import qs from 'qs';
import { apiRetry } from '@@/shared/utilites/api-retry';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type createServer from 'connect';
import { MENU_TYPE } from '@@/shared/constants/menuType';

let _axios: null | NuxtAxiosInstance = null;

export default defineNuxtPlugin(({
  $config,
  $axios,
  req,
}, inject) => {
  processAxiosInstance($axios, req);

  _axios = $axios;

  let endpoint = '';

  if ($config.ENVIRONMENT === 'local') {
    if (process.client) {
      endpoint = `${ location.origin }`;
    } else {
      endpoint = 'https://lk.stranadev-new.com';
    }
  } else if (process.client) {
    endpoint = $config.CLIENT_API_URL;
  } else {
    endpoint = $config.SERVER_API_URL;
  }

  $axios.setBaseURL(endpoint);
  $axios.defaults.timeout = 45 * 1000;
  $axios.defaults.headers.platform = MENU_TYPE.LK_BROKER;
  apiRetry($axios, { retryCount: 5 });

  /* Запросы на портал */
  const portal = $axios.create({
    withCredentials: false,
    headers: {
      platform: 'Broker',
    },
  });

  processAxiosInstance(portal, req);

  let portalEndpoint;

  if ($config.ENVIRONMENT === 'local') {
    if (process.client) {
      portalEndpoint = `${ location.origin }`;
    } else {
      portalEndpoint = 'https://stranadev-new.com';
    }
  } else if (process.client) {
    portalEndpoint = `https://${ $config.MAIN_SITE_HOST }`;
  } else {
    portalEndpoint = `https://${ $config.MAIN_SITE_HOST }`;
    // portalEndpoint = 'http://backend:8000';
  }

  portal.setBaseURL(portalEndpoint);
  portal.defaults.timeout = 45 * 1000;

  inject('portal', portal);

  /* Запросы на сервис лояльности */
  const loyalty = $axios.create({
    withCredentials: false,
  });

  processAxiosInstance(loyalty, req);
  const loyaltyEndpoint = `https://${ $config.SERVICES_SITE_HOST }/loyalty/api`;
  loyalty.setBaseURL(loyaltyEndpoint);
  loyalty.defaults.timeout = 45 * 1000;
  apiRetry(loyalty, { retryCount: 5 });

  inject('loyalty', loyalty);
});

function processAxiosInstance (axios: NuxtAxiosInstance, req: createServer.IncomingMessage): void {
  axios.defaults.paramsSerializer = (params): string => qs
    .stringify(params, { arrayFormat: 'repeat' });
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  const headers = req?.headers ? ({ ...req.headers }) : {};
  axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host'] as string);
  axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port'] as string);
  axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto'] as string);
}

export function useApiClient (): NuxtAxiosInstance {
  if (_axios == null) {
    throw new Error('Axios was not instantiated');
  }

  return _axios;
}

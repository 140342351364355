import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('VApp', {
    class: _vm.$style.UnauthorizedLayout
  }, [_c('TheHeader', {
    attrs: {
      "is-login-button-active": _vm.withLoginButton,
      "is-back-link-active": _vm.withBackLink
    },
    on: {
      "toggle-menu": _vm.toggleHeader
    }
  }), _vm._v(" "), _c('main', {
    class: [_vm.$style.pageWrapper, _defineProperty({}, _vm.$style.fullHeight, _vm.isFixed)]
  }, [_vm._t("default")], 2), _vm._v(" "), _c('client-only', [_vm.isLegalDocumentsRoute ? _c('NFooter') : _c('TheFooter')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
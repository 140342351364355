import { defineStore } from 'pinia';

import { useUserRole } from '@@/shared/composables/useUserRole';
import type {
  Deal,
  FlatForDeal,
} from '@/types/Deal.js';
import { useFlatStore } from '@/store/flat';
import { bookingApiConfig } from '@/config/api/booking';

interface PaymentMethod {
  slug: string;
  isByDev?: boolean; // флаг для субсидированной ипотеки
  mortgageProgramName?: string; // ипотечное предложение
  calculatorOptions?: string; // опции ипотечного калькулятора (json)
  /* calculatorOptions не реализован, т.к. на брокере закрыты доп опции */

}

interface DealAction {
  type: string;
  title: string;
  hint: string;
  text: string;
  button: {
    label: string;
    action: {
      type: string;
      id: string;
    };
  };
}

interface State {
  deal: Deal | object;
  dealActions: Array<DealAction>;
  paymentMethod?: PaymentMethod;
}

/**
 * Преобразуем property из портала для отображения на детальной странице сделки
 * @param {Deal} deal
 * @param{FlatForDeal} flat
 *
 * @return {Deal}
 */
function preparedPropertyFromFlatToDeal (deal: Deal, flat: FlatForDeal): Deal {
  const {
    building,
    ...property
  } = flat;

  return {
    ...deal,
    building: {
      ...deal.building,
      ...building,
    },
    property: {
      ...deal.property,
      ...property,
    },
  };
}

export const useDealStore = defineStore('deal', {
  state: (): State => ({
    deal: {},
    dealActions: [
      {
        type: 'start',
        title: 'требуется сформировать пакет документов',
        hint: 'Пройдите опросник и загрузите пакет документов для начала оформления сделки',
        text: '',
        button: {
          label: 'Начать оформление',
          action: {
            type: 'start-quiz',
            id: 'package_of_documents',
          },
        },
      },
    ],
    paymentMethod: undefined,
  }),

  actions: {
    async getDeal (id: number): Promise<void> {
      try {
        const role = useUserRole();
        const {
          booking,
        } = this.$api.account.dependentRole;
        const url = booking('users', role, id);

        const { data } = await this.$axios.get<Deal>(url);

        /**
         * Было решено, что в детальной странице сделки
         * для отображения блока "Об объекте недвижимости" данные будут браться с портала
         */
        if (data.property?.global_id) {
          const flat: FlatForDeal = await useFlatStore().getFlatForDealDetail(data.property.global_id);

          this.deal = preparedPropertyFromFlatToDeal(data, flat);
        } else {
          this.deal = { ...data };
        }
      } catch (error) {
        this.deal = {};
        console.error('🚀 ~ file: deals.js ~ getDeal ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async setActionCheckStatus (
      payload: {
        id: number;
        slug: string;
      },
    ): Promise<void> {
      try {
        const {
          id,
          slug,
        } = payload;
        const url = bookingApiConfig.taskInstance(id);
        const params = {
          comment: '',
          task_amocrmid: '',
          slug,
        };

        await this.$axios.patch(url, params);
      } catch (error) {
        console.log('🚀 ~ file: deal.js ~ setActionCheckStatus ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async extendClientDeal (id: number): Promise<void> {
      try {
        const url = bookingApiConfig.extendClientFixation(id);

        await this.$axios.patch(url);
      } catch (error) {
        console.log('🚀 ~ file: deal.js ~ getActsByDeal ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    setPaymentMethod (payload: PaymentMethod): void {
      this.paymentMethod = payload;
    },
  },
});

import { SIcon } from '@strana-artw/ui-kit';
import { useHeaderStore } from '@/store/header';
export default {
  name: 'TheHeaderBurger',
  components: {
    SIcon: SIcon
  },
  setup: function setup() {
    return {
      headerStore: useHeaderStore()
    };
  },
  computed: {
    isOpened: function isOpened() {
      return this.headerStore.getHeaderState;
    }
  },
  methods: {
    menuOpen: function menuOpen() {
      this.$emit('clicked');
    }
  }
};
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalAcceptedLegalDocuments_VxxPl{bottom:0;left:0;position:fixed;right:0;top:0;z-index:100}.ModalAcceptedLegalDocuments_VxxPl .overlay_xiuv9{bottom:0;left:0;position:absolute;right:0;top:0;z-index:0}.ModalAcceptedLegalDocuments_VxxPl.hiding_VZMj- .overlay_xiuv9{-webkit-animation:hide-disappear_Nhhj1 .25s ease 0s 1 normal forwards;animation:hide-disappear_Nhhj1 .25s ease 0s 1 normal forwards}.ModalAcceptedLegalDocuments_VxxPl.hiding_VZMj- .modalContent_DNks9{-webkit-animation:hide-swipe_pz8vF .25s ease 0s 1 normal forwards;animation:hide-swipe_pz8vF .25s ease 0s 1 normal forwards}@-webkit-keyframes hide-swipe_pz8vF{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}@keyframes hide-swipe_pz8vF{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}@-webkit-keyframes hide-disappear_Nhhj1{0%{opacity:1}to{opacity:0}}@keyframes hide-disappear_Nhhj1{0%{opacity:1}to{opacity:0}}.modalContent_DNks9{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;background-color:#fff;-ms-flex-direction:column;flex-direction:column;height:100%;max-width:44rem;padding:5.6rem;position:absolute;right:0;width:100vw;z-index:1}@media only screen and (max-width:1279.98px){.modalContent_DNks9{padding:4rem}}@media only screen and (max-width:767.98px){.modalContent_DNks9{padding:1.6rem}}.modalContent_DNks9.loading_cJHMV{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.description_6G06Y{color:#677b8f;font-size:1.4rem;line-height:1.46;margin-bottom:4rem}@media only screen and (max-width:767.98px){.description_6G06Y{margin-bottom:1.6rem}}.policy_TZ54w{margin-top:auto}.saveButton_Wa93n{margin-top:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalAcceptedLegalDocuments": "ModalAcceptedLegalDocuments_VxxPl",
	"overlay": "overlay_xiuv9",
	"hiding": "hiding_VZMj-",
	"hide-disappear": "hide-disappear_Nhhj1",
	"modalContent": "modalContent_DNks9",
	"hide-swipe": "hide-swipe_pz8vF",
	"loading": "loading_cJHMV",
	"description": "description_6G06Y",
	"policy": "policy_TZ54w",
	"saveButton": "saveButton_Wa93n"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useVuexStore } from '@/plugins/app-context';
import type { User } from '@/types/User';

export type Role = 'agent' | 'repres';
export type Strategy = 'agents' | 'represes';

export const useAuthStore = defineStore('authStore', () => {
  // Преобразователь, чтобы мы могли переиспользовать и зависеть от vuex-стора $auth только в одном месте
  const vuex = useVuexStore();
  const user = computed<User | undefined>(() => vuex.$auth?.user);

  const strategy = computed<Strategy>(() => {
    const strategy = vuex.$auth?.$state?.strategy;

    if (!strategy) {
      throw new Error(`User strategy is not defined: ${ strategy }`);
    }

    return strategy;
  });

  const role = computed<Role | null>(() => {
    if (strategy.value === 'represes') {
      return 'repres';
    }

    if (strategy.value === 'agents') {
      return 'agent';
    }

    return null;
  });

  const isAggregator = computed<boolean>(() => {
    return user.value?.agency?.generalType === 'aggregator';
  });

  const loggedIn = computed<boolean>(() => vuex.$auth?.loggedIn || false);

  async function fetchUser () {
    await vuex.$auth.fetchUser();
  }

  function setUser (value: User) {
    vuex.$auth.setUser(value);
  }

  return {
    user,
    fetchUser,
    role,
    isAggregator,
    strategy,
    loggedIn,
    setUser,
  };
});

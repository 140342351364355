import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.loggedIn && !_vm.isLoading && !_vm.isAccepted ? _c('div', {
    class: _vm.$style.AcceptCookies
  }, [_c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.content
  }, [_c('div', {
    class: _vm.$style.left
  }, [_c('SIcon', {
    class: _vm.$style.icon,
    attrs: {
      "icon": "i-cookies",
      "size": "24",
      "color": "#8FA6BD"
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.text
  }, [_vm._v("\n          " + _vm._s(_vm.$t('text')) + "\n        ")])], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.right
  }, [_c('NuxtLink', {
    class: _vm.$style.link,
    attrs: {
      "to": _vm.link,
      "target": "_blank"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('buttonLabel.agreement')) + "\n          "), _c('SIcon', {
    attrs: {
      "icon": "arrow-right",
      "size": "18",
      "color": "#92278F"
    }
  })], 1), _vm._v(" "), _c('SButton', {
    class: _vm.$style.button,
    attrs: {
      "disabled": _vm.isDisabledAcceptedButton,
      "theme": "secondary"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('buttonLabel.accept')) + "\n        ")])], 1)])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import type { GeneralTypes } from '@@/shared/constants/roles';
import { accountApiConfig } from '@/config/api/account';
import { useAxios } from '@/plugins/app-context';

interface Agency {
  inn: string;
  city: string;
  type: 'OOO' | 'IP';
  name: string;
}

interface PersonalData {
  email: string;
  password_1: string;
  password_2: string;
  name: string;
  surname: string;
  patronymic: string;
  city_of_sale_id: number;
  phone: string;
  is_contracted: boolean;
}

interface Repres {
  duty_type?: string;
  general_type?: keyof typeof GeneralTypes;
}

interface RepresesData {
  payload: {
    agency: Agency;
  };

  company_files?: File | null;
  fileCompanyCard?: File | null;
  fileProcuratory?: File | null;
  fileCharter?: File | null;
  fileOgrn?: File | null;
  fileInn?: File | null;
  fileOgrnip?: File | null;
}

interface AgentData extends PersonalData {
  agency: number;
}

interface RegisterRepresRequest {
  repres: PersonalData & Repres;
  agency: Agency;
}

const Errors = {
  TYPICAL: 'Произошла ошибка',
  SERVER: 'Ошибка на сервере',
};

export const useRegistrationStore = defineStore('registration', () => {
  const represesData = ref<Partial<RepresesData>>({});
  const agentData = ref<Partial<AgentData>>({});
  const personalData = ref<Partial<AgentData>>({});

  function setRepresesData (payload: RepresesData): void {
    represesData.value = payload;
  }

  function setAgentData (payload: AgentData): void {
    agentData.value = payload;
  }

  function setPersonalData (payload: AgentData): void {
    personalData.value = payload;
  }

  function resetRegistrationData (): void {
    represesData.value = {};
    agentData.value = {};
    personalData.value = {};
  }

  const $axios = useAxios();

  async function checkUnique ({
    email,
    phone,
    role,
  }: {
    email: string;
    phone: string;
    role: string;
  }): Promise<void> {
    const url = accountApiConfig.checkContacts;
    await $axios.$post(url, {
      email,
      phone,
      role,
    });
  }

  async function represRegistration (payload: FormData) {
    const url = accountApiConfig.register('represes');

    const {
      agency,
    } = await $axios.$post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!agency?.id) {
      throw new Error(Errors.SERVER);
    }
  }

  async function represRegistrationV2 (payload: RegisterRepresRequest) {
    const url = accountApiConfig.registerV2('represes');

    const {
      agency,
    } = await $axios.$post(url, payload);

    if (!agency?.id) {
      throw new Error(Errors.SERVER);
    }
  }

  async function agentRegistration (agencyId: string) {
    const url = accountApiConfig.register('agents');
    const {
      id,
    } = await $axios.$post(url, {
      agency: agencyId,
      ...personalData.value,
      is_contracted: true,
    });

    if (!id) {
      throw new Error(Errors.SERVER);
    }
  }

  return {
    ...decorateApiActions({
      checkUnique,
      represRegistration,
      represRegistrationV2,
      agentRegistration,
    }, 'store/registration', true),
    personalData,
    agentData,
    represesData,
    setRepresesData,
    setAgentData,
    setPersonalData,
    resetRegistrationData,
  };
});

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.template
  }, [_c('main', {
    class: [_vm.$style.main, _defineProperty({}, _vm.$style.fixedContentWidth, _vm.maxContentWidth)],
    style: {
      '--container-width': _vm.maxContentWidth
    }
  }, [_setup.md ? _c('Nuxt', {
    class: _vm.$style.aside,
    style: {
      '--aside': _vm.asideWidth
    },
    attrs: {
      "name": "aside"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.$style.content
  }, [!_setup.md ? _c('Nuxt', {
    class: _vm.$style.mobileMenu,
    attrs: {
      "name": "mobileMenu"
    }
  }) : _vm._e(), _vm._v(" "), _c('Nuxt')], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { defineComponent, onBeforeUnmount, onMounted, useCssModule } from 'vue';
export default defineComponent({
  name: 'LayoutPrint',
  setup: function setup() {
    var $style = useCssModule();
    onMounted(function () {
      document.documentElement.classList.add($style.htmlRoot);
    });
    onBeforeUnmount(function () {
      document.documentElement.classList.remove($style.htmlRoot);
    });
    return {};
  }
});
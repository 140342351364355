import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { SButton, SIcon, SLink } from '@strana-artw/ui-kit';
import { storeToRefs } from 'pinia';
import { BURGER_MENU_ID } from '@@/shared/constants/app-ids';
import { useMenuStore } from '@/store/menu';
import { useHeaderStore } from '@/store/header';
export default {
  name: 'TheBurgerMenu',
  components: {
    SIcon: SIcon,
    SButton: SButton,
    SLink: SLink
  },
  setup: function setup() {
    var menuStore = useMenuStore();
    var _storeToRefs = storeToRefs(menuStore),
      burgerMenu = _storeToRefs.burgerMenu;
    var headerStore = useHeaderStore();
    var toggleHeader = headerStore.toggleHeader;
    return {
      headerStore: headerStore,
      toggleHeader: toggleHeader,
      BURGER_MENU_ID: BURGER_MENU_ID,
      burgerMenu: burgerMenu
    };
  },
  data: function data() {
    return {
      hoveredLink: '',
      isOpened: false,
      links: [{
        name: 'Проектные документы',
        href: 'https://strana.com/documents/'
      }, {
        name: 'Контакты',
        href: 'https://strana.com/contacts/'
      }]
    };
  },
  computed: {
    show: function show() {
      return this.headerStore.getHeaderState;
    },
    menuItems: function menuItems() {
      return this.burgerMenu;
    },
    classes: function classes() {
      return _defineProperty(_defineProperty({}, this.$style._opened, this.show), this.$style['has-banner'], this.hasBanner);
    },
    isMobile: function isMobile() {
      var _window;
      if (!process.client) {
        return false;
      }
      return (_window = window) === null || _window === void 0 || (_window = _window.$nuxt) === null || _window === void 0 ? void 0 : _window.$breakpoint.smAndDown;
    }
  },
  methods: {
    handleMouseEnter: function handleMouseEnter(val) {
      if (this.isMobile) {
        return;
      }
      this.hoveredLink = val;
    },
    handleMouseLeave: function handleMouseLeave() {
      this.hoveredLink = '';
    },
    handleClose: function handleClose() {
      this.toggleHeader();
    }
  }
};
import {
  defineStore,
  storeToRefs,
} from 'pinia';
import {
  computed,
  ref,
} from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useAuthStore } from '../authStore';
import type {
  AgentResponse,
  AgentsOrdering,
  AgentsRequestParams,
} from './types';
import { useAxios } from '@/plugins/app-context';
import { accountApiConfig } from '@/config/api/account';
import type { PageInfo } from '@/types/PageInfo';
import type { Agent } from '@/types/Agent';

export type AgentsList = Array<Agent>;
export type PagedAgents = Array<AgentsList>;

const orderingDateCreate = [
  {
    label: 'По убыванию имени',
    value: '-name',
  },
  {
    label: 'По возрастанию имени',
    value: 'name',
  },
];

export const useAgentsStore = defineStore('agents', () => {
  const authStore = useAuthStore();
  const { strategy } = storeToRefs(authStore);
  const pagedAgents = ref<PagedAgents>([]);
  const agents = computed(() => pagedAgents.value.flat(1));

  const count = ref(0);
  const pageInfo = ref<PageInfo | null>(null);
  const ordering = ref<AgentsOrdering | null>(null);
  const search = ref<string | null>(null);

  async function getAgents (params: AgentsRequestParams, append = false) {
    const { dependentRole } = accountApiConfig;

    if (!strategy.value) {
      throw new Error('auth strategy info not received');
    }
    const url = dependentRole.list('agents', strategy.value);
    const response = await useAxios().$get<AgentResponse>(url, { params });

    if (!append) {
      pagedAgents.value.splice(0);
    }

    if (response.result.length) {
      pagedAgents.value.push(response.result);
    }

    count.value = response.count;
    pageInfo.value = response.page_info;

    return response;
  }

  async function getSpecs () {
    ordering.value = orderingDateCreate;
  }

  const decoratedApiActions = decorateApiActions({
    getAgents,
    getSpecs,
  }, 'agents/agents.ts');

  const decoratedApiActionsRethrow = decorateApiActions({
    getAgentsRethrow: getAgents,
  }, 'agents/agents.ts', true);

  return {
    agents,
    pagedAgents,
    count,
    pageInfo,
    ordering,
    search,
    ...decoratedApiActions,
    ...decoratedApiActionsRethrow,
  };
});

import {
  type MaybeRefOrGetter,
  toValue,
} from '@vueuse/core';
import {
  type ComputedRef,
  type Ref,
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

// FIXME Это стырено из моего же участка в helpers в ui-kit. Нужно бы экспортировать оттуда всякие такие полезные мелочи
/**
 * Проверяет, есть ли `potentialParent` среди родителей `target`
 *
 * В какой-то степени аналог `Element.closest`, только без селекторов
 * @param target элемент, среди родителей которого будем искать `potentialParent`
 * @param potentialParent элемент, который будем искать среди родителей `target`
 * @returns `true` - если `potentialParent` - родитель любого уровня вложенности для `target`, иначе `false`
 */
export function isInParents (target: Element, potentialParent: Element): boolean {
  if (target === potentialParent) {
    return true;
  }

  while (target.parentElement !== potentialParent) {
    if (target.parentElement == null) {
      return false;
    }
    target = target.parentElement;
  }

  return true;
}

// FIXME: а это тоже стоит в ui-kit сунуть и экспортить оттуда
/**
 * Вызовет `functor`, если клик в документе не будет находиться внутри дерева потомков `parent` (включая его самого)
 *
 * Вызывать строго в setup (не хочу делать для options). Сама подпишется на клик, сама приберётся.
 * @param parent
 * @param functor
 */
export function useClickOutside (parent: MaybeRefOrGetter<Element | null>, functor: (e: Event) => void): void {
  function onDocumentClick (e: Event): void {
    const parentEl = toValue(parent);

    if (parentEl == null) {
      return;
    }
    const target = e.target as Element;

    if (!isInParents(target, parentEl)) {
      functor(e);
    }
  }

  onMounted(() => {
    document.addEventListener('click', onDocumentClick);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', onDocumentClick);
  });
}

interface UseFocusEventsArgs {
  onFocusInside(e: Event): void;
  onFocusOutside(e: Event): void;
}

export function useFocusEvents (parent: MaybeRefOrGetter<Element | null>, args: Partial<UseFocusEventsArgs>): void {
  if (args.onFocusInside == null && args.onFocusOutside == null) {
    throw new Error('Ошибка: ни один callback не предоставлен. [useFocusEvents]');
  }

  function onDocumentFocusIn (e: Event): void {
    const parentEl = toValue(parent);

    if (parentEl == null) {
      return;
    }
    const target = e.target as Element;

    if (isInParents(target, parentEl)) {
      args.onFocusInside?.(e);
    } else {
      args.onFocusOutside?.(e);
    }
  }

  onMounted(() => {
    document.addEventListener('focusin', onDocumentFocusIn);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('focusin', onDocumentFocusIn);
  });
}

const defaultVisibleFrame = {
  opacity: 1,
};
const defaultInvisibleFrame = {
  opacity: 0,
};

interface UseOpenHideAnimationReturn {
  animate(forward: boolean): void;
  isHidden: ComputedRef<boolean>;
}

type Keyframes = Parameters<Element['animate']>[0];

export function useOpenHideAnimation (elRef: Ref<Element | null | undefined>, isHiddenDefault = false): UseOpenHideAnimationReturn {
  let animation: ReturnType<Element['animate']> | null = null;
  const isHidden = ref(isHiddenDefault);

  function getFrames (forward: boolean): Keyframes {
    return forward ? [defaultInvisibleFrame, defaultVisibleFrame] : [defaultVisibleFrame, defaultInvisibleFrame];
  }

  function animate (forward: boolean): void {
    const el = elRef.value;

    if (el == null) {
      return;
    }

    animation?.cancel();

    if (forward) {
      isHidden.value = false;
    }

    animation = el.animate(getFrames(forward), {
      duration: 200,
    });

    if (!forward) {
      animation.addEventListener('finish', () => {
        isHidden.value = true;
      });
    }
  }

  return {
    animate,
    isHidden: computed(() => isHidden.value),
  };
}

/**
 * Декоратор, ограничивающий срабатывание функции частотой кадров.
 */
export const rAFdebounce = <TArgs extends Array<unknown>>(fn: (...args: TArgs) => unknown): (...args: TArgs) => void => {
  let frame: ReturnType<typeof requestAnimationFrame> | undefined;

  return (...args: TArgs): void => {
    if (frame != null) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => {
      fn(...args);
    });
  };
};

type UseRAFCallback = (elapsed: number) => boolean;
interface UseRAFHandler {
  /**
   * Запустить регулярный вызов `requestAnimationFrame`
   */
  start(): void;
  /**
   * Остановить регулярный вызов `requestAnimationFrame`
   */
  stop(): void;
}
/**
 * Обвязка типичного бойлерплейта при использовании {@link https://developer.mozilla.org/ru/docs/Web/API/Window/requestAnimationFrame|requestAnimationFrame}.
 *
 * Позволяет стартовать и тормозить регулярный вызов `requestAnimationFrame`.
 *
 * Может быть полезно при создании необычных анимаций.
 * @param callback будет вызываться каждый кадр, получает в качестве параметра `elapsed` - время в мс, прошедшее с рендера предыдущего кадра.
 * Должна вернуть `true`, если анимация продолжается, `false` если должна закончиться
 * @returns экземпляр {@link UseRAFHandler}
 */
export function useRAF (callback: UseRAFCallback): UseRAFHandler {
  let previousTimeFromOrigin = 0;
  let descriptor: ReturnType<typeof requestAnimationFrame> | null = null;
  let running = false;
  function frame (timeFromOrigin: number) {
    const elapsed = timeFromOrigin - previousTimeFromOrigin;

    if (callback(elapsed) && running) {
      descriptor = requestAnimationFrame(frame);
    } else {
      running = false;
    }
    previousTimeFromOrigin = timeFromOrigin;
  }

  function start () {
    if (running) {
      return;
    }
    running = true;

    if (typeof document.timeline !== 'undefined' && document.timeline.currentTime != null) {
      previousTimeFromOrigin = Number(document.timeline.currentTime);
    } else {
      requestAnimationFrame((timeFromOrigin) => {
        previousTimeFromOrigin = timeFromOrigin;
      });
    }
    descriptor = requestAnimationFrame(frame);
  }

  function stop () {
    running = false;

    if (descriptor != null) {
      cancelAnimationFrame(descriptor);
    }
  }

  return {
    start,
    stop,
  };
}

export function errorByValueMessage (args: Record<string, unknown>) {
  return Object.entries(args)
    .map(([key, value]) => {
      let prepared = value;
      if (typeof prepared === 'string') {
        prepared = prepared.length ? `"${ prepared }"` : 'empty string';
      }
      return `${ key } was ${ prepared }`;
    })
    .join('\n');
}

import { useAuthActionsStore } from '@@/lk_broker/store/authActions';

export default async function ({
  $auth,
  $pinia,
  store,
}) {
  $auth.onRedirect((to) => {
    const strategy = $auth.$state.strategy || '';

    if (strategy) {
      to = '/login';
    }
  });
  const { executeLoginActions, executeLogoutActions } = useAuthActionsStore($pinia);

  if ($auth.loggedIn) {
    await executeLoginActions(store);
  }
  $auth.$storage.watchState('loggedIn', async (isLoggedIn) => {
    if (isLoggedIn) {
      await executeLoginActions(store);
    } else {
      await executeLogoutActions(store);
    }
  });
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegistrationPolicy_s897D{margin-bottom:2.4rem}@media only screen and (max-width:767.98px){.RegistrationPolicy_s897D{margin-bottom:2rem}}.RegistrationPolicy_s897D .checkbox_of8Yx:not(:last-child){margin-bottom:1.6rem}.RegistrationPolicy_s897D .text_3JMuQ{color:#012345;font-size:1.4rem;font-style:normal;font-weight:400;letter-spacing:-.014rem;line-height:140%}.RegistrationPolicy_s897D .text_3JMuQ a{color:#be7dbc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RegistrationPolicy": "RegistrationPolicy_s897D",
	"checkbox": "checkbox_of8Yx",
	"text": "text_3JMuQ"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useAxios } from '@/plugins/app-context';
import { eventsApiConfig } from '@/config/api/events';

type StagesKeys =
  'ACCEPT_EVENT' |
  'REFUSE_EVENT' |
  'ACCEPT_EVENT_SUCCESS' |
  'REFUSE_EVENT_SUCCESS';

type Stage = {
  [_key in StagesKeys]: string;
};

const STAGES: Stage = {
  ACCEPT_EVENT: 'EventAction',
  REFUSE_EVENT: 'EventAction',
  ACCEPT_EVENT_SUCCESS: 'EventActionSuccess',
  REFUSE_EVENT_SUCCESS: 'EventActionSuccess',
};

interface EventType {
  label: string;
  value: string;
}

interface EventTag {
  name: string;
  color: string;
  textColor: string;
}

interface EventResponse {
  id: number;
  name: string;
  type: EventType;
  dateStart: Date;
  dateEnd?: Date;
  isParticipant: boolean;
  hasEmptySeats: boolean;
  tags: Array<EventTag>;
}

interface City {
  id: number;
  name: string;
  slug: string;
}

interface File {
  aws: string;
  proxy: string | null;
  s3: string;
  src: string;
}

interface EventDetailResponse extends EventResponse {
  description?: string;
  comment?: string;
  city?: City;
  address?: string;
  link?: string;
  image?: File;
  recordDateEnd?: Date;
  managerFullName: string;
  managerPhone: string;
  maxParticipantsCount?: number;
  participantsCount?: number;
}

interface QRCodeData {
  code: string;
  title: string;
  subtitle: string;
  eventDate: string;
  timeslot: string;
  text: string;
}

export const useEventStore = defineStore('events', () => {
  const stateEventsAction = ref<string | undefined>(undefined);
  const event = ref<EventDetailResponse | undefined>(undefined);
  const qrCodeData = ref<QRCodeData | null>(null);

  async function loadEvent (id: number) {
    const url = eventsApiConfig.getEvent(id);
    const response = await useAxios().get<EventDetailResponse>(url);
    event.value = response.data;
  }

  async function acceptEvent (id: number) {
    const url = eventsApiConfig.accept(id);
    const response = await useAxios().patch<EventDetailResponse>(url);
    event.value = response.data;
  }

  async function refuseEvent (id: number) {
    const url = eventsApiConfig.refuse(id);
    const response = await useAxios().patch<EventDetailResponse>(url);
    event.value = response.data;
  }

  function setState (stage: StagesKeys) {
    stateEventsAction.value = STAGES[stage];
  }

  async function loadQRCode () {
    const url = eventsApiConfig.getQRCode;
    const response = await useAxios().get<QRCodeData | null>(url);
    qrCodeData.value = response.data;
  }

  function resetQRCode () {
    qrCodeData.value = null;
  }

  return {
    stateEventsAction,
    event,
    qrCodeData,
    setState,
    resetQRCode,
    ...decorateApiActions({
      loadEvent,
      acceptEvent,
      refuseEvent,
      loadQRCode,
    }, 'eventPinia', true),
  };
});

import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import type {
  ComputedRef,
  Ref,
} from 'vue';
import { pageScrollService } from '@@/shared/services/PageScrollService';
import {
  useClickOutside,
  useFocusEvents,
} from '@@/shared/utilites/misc';
import { useScreenPlugin } from '@/plugins/vue-screen';

interface HasEl {
  $el: Element;
}
type MenuRefArg = Ref<HasEl | Element | null>;

interface UseMenuLogicReturn {
  close(): void;
  open(): void;
  menuVisible: ComputedRef<boolean>;
}
type ScreenArg = ReturnType<typeof useScreenPlugin>;

export function useMenuLogic (menuRef: MenuRefArg, screen: ScreenArg = useScreenPlugin()): UseMenuLogicReturn {
  const menuVisible = ref(false);

  const menuRefEl = computed(() => {
    if (menuRef.value == null) {
      return null;
    }

    if ('$el' in menuRef.value) {
      return menuRef.value.$el;
    }

    return menuRef.value;
  });

  function close (): void {
    menuVisible.value = false;

    if (screen.isMobileOrTablet) {
      pageScrollService.unlock([menuRefEl.value] as Array<HTMLElement>);
    }
  }

  function open (): void {
    menuVisible.value = true;

    if (screen.isMobileOrTablet) {
      pageScrollService.lock([menuRefEl.value] as Array<HTMLElement>);
    }
  }

  let timeoutDescriptor = null as null | ReturnType<typeof setTimeout>;

  function onMouseEnter (): void {
    if (!screen.isDesktop) {
      return;
    }

    open();

    if (timeoutDescriptor != null) {
      clearTimeout(timeoutDescriptor);
      timeoutDescriptor = null;
    }
  }

  function onMouseLeave (): void {
    if (!screen.isDesktop) {
      return;
    }
    timeoutDescriptor = setTimeout(close, 300);
  }

  useFocusEvents(menuRefEl, {
    onFocusInside (e) {
      if (!screen.isDesktop) {
        return;
      }

      open();
    },
    onFocusOutside (e) {
      if (!screen.isDesktop) {
        return;
      }

      close();
    },
  });

  useClickOutside(menuRefEl, () => {
    if (menuVisible.value && screen.isDesktop) {
      close();
    }
  });

  onMounted(() => {
    if (menuRefEl.value) {
      menuRefEl.value.addEventListener('mouseenter', onMouseEnter);
      menuRefEl.value.addEventListener('mouseleave', onMouseLeave);
    }
  });

  onBeforeUnmount(() => {
    if (menuRefEl.value) {
      menuRefEl.value.removeEventListener('mouseenter', onMouseEnter);
      menuRefEl.value.removeEventListener('mouseleave', onMouseLeave);
    }
    close();
  });

  return {
    close,
    open,
    menuVisible: computed(() => menuVisible.value),
  };
}

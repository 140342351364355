import { defineStore } from 'pinia';
import {
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import type {
  Notifications,
  NotificationsResponse,
} from '@/types/Notifications';

export const useNotificationsStore = defineStore('notifications', () => {
  const {
    $axios,
    $sentry,
    $api,
  } = useContext();
  const notifications = ref<Notifications>([]);

  async function getNotifications (): Promise<void> {
    try {
      const url = $api.account.getCautions;
      const { data } = await $axios.get<NotificationsResponse>(url);
      const { warnings } = data;

      notifications.value = warnings ?? [];
    } catch (error) {
      console.error('🚀 ~ file: notification.ts ~~ getNotifications ~ e', error);
      $sentry.captureException(error);
    }
  }

  async function removeNotification (id: number): Promise<void> {
    try {
      const url = $api.account.deleteCaution(id);
      await $axios.$delete(url);
      await getNotifications();
    } catch (error) {
      console.error('🚀 ~ file: notification.ts ~~ removeNotification ~ e', error);
      $sentry.captureException(error);
    }
  }

  return {
    notifications,
    getNotifications,
    removeNotification,
  };
});

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useAuthStrategy } from '@@/shared/composables/useAuthStrategy';
import { useAuthStore } from './authStore';
import type { Agent } from '@/types/Agent';
import { fireApiConfig } from '@/config/api/fire';
import { useApiClient } from '@/plugins/axios';

interface ActionsStatus {
  id: number;
  name: string;
  slug: string;
  roleId: number;
}

interface CrmStatus {
  id: number;
  name: string;
  color: string | null;
  stepsNumbers: number;
  currentStep: number;
  actions?: Array<ActionsStatus> | null;
}

interface Projects {
  id: number;
  globalId: string;
  slug: string;
  name: string;
  city: string;
  status: {
    value: string;
    label: string;
  };
}

interface Floor {
  id: number;
  number: string;
}

interface Property {
  id: number;
  price: number;
  rooms: number;
  number: string;
  article: string;
  area: number;
  discount: number | null;
  globalId: string;
  backendId: string;
  originalPrice: number;
  finalPrice: number;
  floor: Floor;
  type: string | null;
}

interface Deal {
  id: number;
  amocrmId: number;
  paymentAmount: number | null;
  finalPaymentAmount: number;
  expires?: string;
  until?: string;
  user: Agent;
  amocrmStatus: CrmStatus;
  project: Projects;
  property: Property;
}

interface FireAgentResponse {
  bookings: Array<Deal>;
}

export const useFiredStore = defineStore('fired', () => {
  const deals = ref<Array<Deal> | undefined>(undefined);
  const currentAgent = ref<number | undefined>(undefined);

  const authStore = useAuthStore();

  const setCurrentAgent = (payload: number) => {
    currentAgent.value = payload;
  };

  const agentFired = async (id?: number) => {
    const strategy = useAuthStrategy();

    if (id && strategy) {
      const url = fireApiConfig.byRole(strategy);
      const { bookings } = await useApiClient().$patch<FireAgentResponse>(url, null, {
        params: {
          agent_id: id,
        },
      });
      deals.value = bookings;
    } else {
      const url = fireApiConfig.agent;
      await useApiClient().$patch(url);
      await authStore.fetchUser();
    }
  };

  const decoratedApiActions = decorateApiActions({
    agentFired,
  }, 'store/fired.ts', true);

  return {
    deals,
    currentAgent,
    setCurrentAgent,
    ...decoratedApiActions,
  };
});

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutPrint_xTdp5{--full-a4-height:1122.4624180095px}@page{margin:0;size:a4}.htmlRoot_zTsh3,.htmlRoot_zTsh3 body{-webkit-print-color-adjust:exact;print-color-adjust:exact}.htmlRoot_zTsh3 [id^=textback_widget],.htmlRoot_zTsh3 body [id^=textback_widget]{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutPrint": "LayoutPrint_xTdp5",
	"htmlRoot": "htmlRoot_zTsh3"
};
module.exports = ___CSS_LOADER_EXPORT___;

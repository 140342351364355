export const LEGAL_DOCUMENTS_SLUGS = {
  politic: 'politic',
  accept: 'accept_broker',
  brokerOffer: 'broker_offer',
  brokerLoyaltyProgram: 'broker_loyalty_program',
  agreement: 'agreement',
};

export const LEGAL_DOCUMENTS_ACCEPTED = {
  repres: [
    LEGAL_DOCUMENTS_SLUGS.politic,
    LEGAL_DOCUMENTS_SLUGS.accept,
    LEGAL_DOCUMENTS_SLUGS.brokerOffer,
    LEGAL_DOCUMENTS_SLUGS.brokerLoyaltyProgram,
  ],
  agent: [
    LEGAL_DOCUMENTS_SLUGS.politic,
    LEGAL_DOCUMENTS_SLUGS.accept,
  ],
  aggregator: [
    LEGAL_DOCUMENTS_SLUGS.politic,
    LEGAL_DOCUMENTS_SLUGS.accept,
  ],
  cookies: [
    LEGAL_DOCUMENTS_SLUGS.agreement,
  ],
};

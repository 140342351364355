import { storeToRefs } from 'pinia';
import { useUserRole } from '@@/shared/composables/useUserRole';
import { computed } from 'vue';
import { LEGAL_DOCUMENTS_ACCEPTED } from '@@/shared/constants/legalDocuments';
import { useLegalDocumentsStore } from '@/store/documents';
import { useAuthStore } from '@/store/authStore';

export const useLegalDocuments = () => {
  const role = useUserRole();
  const authStore = useAuthStore();
  const { isAggregator } = storeToRefs(authStore);

  const legalDocumentsStore = useLegalDocumentsStore();
  const { acceptedDocuments } = storeToRefs(legalDocumentsStore);

  const acceptedLegalDocuments = (slugs: Array<string>) => {
    return acceptedDocuments.value
      .filter(({ documentSlug, isAccepted }) => {
        return slugs.includes(documentSlug) && !isAccepted;
      })
      .map(({ documentSlug }) => documentSlug);
  };

  /**
   * Список слагов для принятых документов в зависимости от роли
   * @param {boolean } withCookies добавляем в список слаг документа принятия cookies
   *
   * @returns Список слагов
   */
  const documentsSlugsWithRole = (withCookies?: boolean) => {
    const isAgent = computed(() => role === 'agent');

    const documentsSlugs = isAgent.value
      ? LEGAL_DOCUMENTS_ACCEPTED.agent
      : isAggregator.value
        ? LEGAL_DOCUMENTS_ACCEPTED.aggregator
        : LEGAL_DOCUMENTS_ACCEPTED.repres;

    if (withCookies) {
      return [
        ...documentsSlugs,
        ...LEGAL_DOCUMENTS_ACCEPTED.cookies,
      ];
    }

    return documentsSlugs;
  };

  return {
    acceptedLegalDocuments,
    documentsSlugsWithRole,
  };
};

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { set } from 'vue-demi';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useApiClient } from '@/plugins/axios';
import { mainPageApiConfig } from '@/config/api/mainPage';

interface Image {
  src: string;
  s3: string;
  aws: string;
  proxy?: string;
}

interface ContentItem {
  image: Image;
  description: string;
  title: string;
}

interface Content {
  sellOnline?: Array<ContentItem>;
  partnerLogo?: Array<ContentItem>;
  offer?: Array<ContentItem>;
}

interface Manager {
  lastname: string;
  name: string;
  patronymic: string;
  phone: string;
  photo?: Image;
  position: string;
  workSchedule: string;
}

export const useMainPageStore = defineStore('MainPage', () => {
  const content = ref<Content>({});
  const manager = ref<Manager | undefined>(undefined);

  const setManager = (payload: Manager) => {
    manager.value = { ...payload };
  };

  const setContent = (payload: Content) => {
    for (const prop in payload) {
      const key = prop as keyof Content;
      set(content.value, key, payload[key]);
    }
  };

  const getSellOnline = async () => {
    const url: string = mainPageApiConfig.sellOnline;
    const { data } = await useApiClient().get<Array<ContentItem>>(url);
    setContent({ sellOnline: data });
  };

  const getPartnerLogo = async () => {
    const url: string = mainPageApiConfig.partnerLogo;
    const { data } = await useApiClient().get<Array<ContentItem>>(url);
    setContent({ partnerLogo: data });
  };

  const getOffer = async () => {
    const url: string = mainPageApiConfig.offer;
    const { data } = await useApiClient().get<Array<ContentItem>>(url);
    setContent({ offer: data });
  };

  const getManager = async () => {
    const url: string = mainPageApiConfig.headOfPartnersDepartment;
    const { data } = await useApiClient().get<Manager>(url);
    setManager(data);
  };

  const decoratedApiActions = decorateApiActions({
    getSellOnline,
    getPartnerLogo,
    getOffer,
    getManager,
  }, 'mainPage', true);

  return {
    content,
    manager,
    ...decoratedApiActions,
  };
});

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.Layout
  }, [_c('div', {
    class: _vm.$style.header
  }, [_c(_setup.NHeader, {
    on: {
      "menu-button-click": _setup.menuLogic.open
    }
  }), _vm._v(" "), _setup.isLogin && !_setup.isFired ? _c('transition-group', {
    attrs: {
      "name": "card",
      "tag": "div"
    }
  }, _vm._l(_setup.notifications, function (notification) {
    return _c('TopNotification', {
      key: notification.id,
      class: _vm.$style.card,
      attrs: {
        "id": notification.id,
        "type": notification.type.value
      },
      on: {
        "close": _setup.removeNotification
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(notification.text) + "\n        ")];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c(_setup.BackdropOverlay, {
    class: _vm.$style.backdropOverlay,
    attrs: {
      "visible": _setup.backdropVisible
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.menuLogic.close.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c('div', _vm._b({
    ref: "menuRef",
    class: _vm.$style.menuWrapper
  }, "div", _vm._d({}, [_setup.iosScrollShouldLockAttributeNameCrutch, true])), [_setup.screen.isDesktop ? _c(_setup.MenuDesktopActivator) : _vm._e(), _vm._v(" "), _c(_setup.NMenu, {
    ref: "menuRef",
    class: [_vm.$style.menu, _defineProperty({}, _vm.$style.visible, _setup.menuVisible)],
    on: {
      "menu-item-click": _setup.onMenuItemClick
    }
  })], 1), _vm._v(" "), _c('div', [_vm._t("default")], 2), _vm._v(" "), _vm.hasFooter ? _c('client-only', [_c(_setup.NFooter)], 1) : _vm._e(), _vm._v(" "), _c(_setup.NScrollUpButton), _vm._v(" "), _setup.isShowCookies ? _c(_setup.AcceptCookies) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.$style.modalPlace
  }, [_c('TheModal'), _vm._v(" "), _c(_setup.TheModalIframe), _vm._v(" "), _c('TheContextMenu'), _vm._v(" "), _c(_setup.PortalTarget, {
    attrs: {
      "name": "layout",
      "multiple": ""
    }
  }), _vm._v(" "), _c(_setup.NotificationsContainer), _vm._v(" "), _setup.isShowModalAcceptedLegalDocuments ? _c(_setup.ModalAcceptedLegalDocuments) : _vm._e(), _vm._v(" "), _setup.userAuthed && !_setup.hasCityOfSale ? _c(_setup.ModalSelectSaleCity) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
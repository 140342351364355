import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
var _excluded = ["handler", "noDismiss", "style", "class", "attrs"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
// Components
import { SLoader } from '@strana-artw/ui-kit';
import VIcon from '../../components/ui/VIcon';
import VBtn from '../../components/ui/VBtn';
import VAvatar from '../../components/ui/VAvatar';
var uid = 0;
var defaults = {};
var positionList = ['top-left', 'top-right', 'bottom-left', 'bottom-right', 'top', 'bottom', 'left', 'right', 'center'];
var badgePositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
var groups = {};
var positionClass = {};
import { consoleError } from '../../utils/console';
export default {
  name: 'VNotifications',
  created: function created() {
    var _this = this;
    this.notifs = {};
    positionList.forEach(function (pos) {
      _this.notifs[pos] = [];
      var vert = ['left', 'center', 'right'].includes(pos) === true ? 'center' : pos.includes('top') ? 'top' : 'bottom';
      var align = pos.includes('left') ? 'start' : pos.includes('right') ? 'end' : 'center';
      var classes = ['left', 'right'].includes(pos) ? "items-".concat(pos === 'left' ? 'start' : 'end', " justify-center") : pos === 'center' ? 'flex-center' : "items-".concat(align);
      positionClass[pos] = "v-notifications__list v-notifications__list--".concat(vert, " ").concat(classes);
      // fixed column no-wrap
    });
  },
  methods: {
    add: function add(config, originalApi) {
      var _this2 = this;
      if (!config) {
        return consoleError('parameter required');
      }
      var Api;
      var notif = {
        textColor: 'white'
      };
      if (config.ignoreDefaults !== true) {
        Object.assign(notif, defaults);
      }
      if (Object(config) !== config) {
        if (notif.type) {
          Object.assign(notif, originalApi.types[notif.type]);
        }
        config = {
          message: config
        };
      }
      Object.assign(notif, originalApi.types[config.type || notif.type], config);
      if (typeof notif.icon === 'function') {
        notif.icon = notif.icon.call(this);
      }
      if (notif.spinner === undefined) {
        notif.spinner = false;
      } else if (notif.spinner === true) {
        notif.spinner = SLoader;
      }
      notif.meta = {
        hasMedia: Boolean(notif.spinner !== false || notif.icon || notif.avatar)
      };
      if (notif.position) {
        if (positionList.includes(notif.position) === false) {
          return consoleError('wrong position', config);
        }
      } else {
        notif.position = 'bottom';
      }
      if (notif.timeout === undefined) {
        notif.timeout = 5000;
      } else {
        var t = parseInt(notif.timeout, 10);
        if (isNaN(t) || t < 0) {
          return consoleError('wrong timeout', config);
        }
        notif.timeout = t;
      }
      if (notif.timeout === 0) {
        notif.progress = false;
      } else if (notif.progress === true) {
        notif.meta.progressStyle = {
          animationDuration: "".concat(notif.timeout + 1000, "ms")
        };
      }
      var actions = (Array.isArray(config.actions) === true ? config.actions : []).concat(config.ignoreDefaults !== true && Array.isArray(defaults.actions) === true ? defaults.actions : []).concat(originalApi.types[config.type] !== undefined && Array.isArray(originalApi.types[config.type].actions) === true ? originalApi.types[config.type].actions : []);
      notif.closeBtn && actions.push({
        label: typeof notif.closeBtn === 'string' ? notif.closeBtn : 'Закрыть'
      });
      notif.actions = actions.map(function (_ref) {
        var handler = _ref.handler,
          noDismiss = _ref.noDismiss,
          style = _ref.style,
          klass = _ref.class,
          attrs = _ref.attrs,
          props = _objectWithoutProperties(_ref, _excluded);
        return {
          staticClass: klass,
          style: style,
          props: _objectSpread({
            flat: true
          }, props),
          attrs: attrs,
          on: {
            click: typeof handler === 'function' ? function () {
              handler();
              // eslint-disable-next-line
              noDismiss !== true && dismiss();
            } : function () {
              // eslint-disable-next-line
              dismiss();
            }
          }
        };
      });
      if (notif.multiLine === undefined) {
        notif.multiLine = notif.actions.length > 1;
      }
      Object.assign(notif.meta, {
        staticClass: "v-notification row items-stretch" + " v-notification--".concat(notif.multiLine === true ? 'multi-line' : 'standard').concat(notif.color !== undefined ? " v-notification-".concat(notif.color) : '').concat(notif.textColor !== undefined ? " ".concat(notif.textColor, "--text") : '').concat(notif.classes !== undefined ? " ".concat(notif.classes) : ''),
        //  relative-position border-radius-inherit
        wrapperClass: "v-notification__wrapper col ".concat(notif.multiLine === true ? 'column no-wrap justify-center' : 'row items-center'),
        contentClass: "v-notification__content ".concat(notif.multiLine === true ? '' : ' col'),
        // row items-center
        attrs: _objectSpread({
          role: 'alert'
        }, notif.attrs)
      });
      if (notif.group === false) {
        notif.group = undefined;
        notif.meta.group = undefined;
      } else {
        if (notif.group === undefined || notif.group === true) {
          // do not replace notifications with different buttons
          notif.group = [notif.message, notif.caption, notif.multiline].concat(notif.actions.map(function (a) {
            return "".concat(a.props.label, "*").concat(a.props.icon);
          })).join('|');
        }
        notif.meta.group = "".concat(notif.group, "|").concat(notif.position);
      }
      if (notif.actions.length === 0) {
        notif.actions = undefined;
      } else {
        notif.meta.actionsClass
        //  row items-center
        = "v-notification__actions ".concat(notif.multiLine === true ? 'justify-end' : 'col-auto').concat(notif.meta.hasMedia === true ? ' v-notification__actions--with-media' : '');
      }

      // if (originalApi !== undefined) {
      // // reset timeout if any
      // clearTimeout(originalApi.notif.meta.timer);

      // // retain uid
      // notif.meta.uid = originalApi.notif.meta.uid;

      // // replace notif
      // const index = this.notifs[notif.position].indexOf(originalApi.notif);
      // this.notifs[notif.position][index] = notif;
      // } else {
      var original = groups[notif.meta.group];

      // woohoo, it's a new notification
      if (original === undefined) {
        notif.meta.uid = uid++;
        notif.meta.badge = 1;
        if (['left', 'right', 'center'].includes(notif.position)) {
          this.notifs[notif.position].splice(Math.floor(this.notifs[notif.position].length / 2), 0, notif);
        } else {
          var action = notif.position.includes('top') ? 'unshift' : 'push';
          this.notifs[notif.position][action](notif);
        }
        if (notif.group !== undefined) {
          groups[notif.meta.group] = notif;
        }
      } else {
        // ok, so it's NOT a new one
        // reset timeout if any
        clearTimeout(original.meta.timer);
        if (notif.badgePosition !== undefined) {
          if (badgePositions.includes(notif.badgePosition) === false) {
            return consoleError('wrong badgePosition', config);
          }
        } else {
          notif.badgePosition = "top-".concat(notif.position.includes('left') ? 'right' : 'left');
        }
        notif.meta.uid = original.meta.uid;
        notif.meta.badge = original.meta.badge + 1;
        notif.meta.badgeStaticClass = "v-notification__badge v-notification__badge--".concat(notif.badgePosition).concat(notif.badgeColor !== undefined ? " ".concat(notif.badgeColor) : '').concat(notif.badgeTextColor !== undefined ? " ".concat(notif.badgeTextColor, "--text") : '');
        var index = this.notifs[notif.position].indexOf(original);
        this.notifs[notif.position][index] = groups[notif.meta.group] = notif;
      }
      // }

      var dismiss = function dismiss() {
        _this2.remove(notif);
        Api = undefined;
      };
      this.$forceUpdate();
      if (notif.timeout > 0) {
        notif.meta.timer = setTimeout(function () {
          dismiss();
        }, notif.timeout + /* show duration */1000);
      }

      // only non-groupable can be updated
      if (notif.group !== undefined) {
        return function (props) {
          if (props !== undefined) {
            consoleError('trying to update a grouped one which is forbidden', config);
          } else {
            dismiss();
          }
        };
      }
      Api = {
        dismiss: dismiss,
        config: config,
        notif: notif
      };
      console.log('🚀 ~ file: Notify.vue ~ line 340 ~ add ~ originalApi', originalApi);
      if (originalApi !== undefined) {
        Object.assign(originalApi, Api);
        return;
      }
      return function (props) {
        // if notification wasn't previously dismissed
        if (Api !== undefined) {
          // if no params, then we must dismiss the notification
          if (props === undefined) {
            Api.dismiss();
          } else {
            // otherwise we're updating it
            var newNotif = _objectSpread(_objectSpread(_objectSpread({}, Api.config), props), {}, {
              group: false,
              position: notif.position
            });
            _this2.add(newNotif, Api);
          }
        }
      };
    },
    remove: function remove(notif) {
      clearTimeout(notif.meta.timer);
      var index = this.notifs[notif.position].indexOf(notif);
      if (index !== -1) {
        if (notif.group !== undefined) {
          delete groups[notif.meta.group];
        }
        var el = this.$refs["notif_".concat(notif.meta.uid)];
        if (el) {
          var _getComputedStyle = getComputedStyle(el),
            width = _getComputedStyle.width,
            height = _getComputedStyle.height;
          el.style.left = "".concat(el.offsetLeft, "px");
          el.style.width = width;
          el.style.height = height;
        }
        this.notifs[notif.position].splice(index, 1);
        this.$forceUpdate();
        if (typeof notif.onDismiss === 'function') {
          notif.onDismiss();
        }
      }
    }
  },
  render: function render(h) {
    var _this3 = this;
    return h('div', {
      staticClass: 'v-notifications'
    }, positionList.map(function (pos) {
      return h('transition-group', {
        key: pos,
        staticClass: positionClass[pos],
        tag: 'div',
        props: {
          name: "v-notification--".concat(pos),
          mode: 'out-in'
        }
      }, _this3.notifs[pos].map(function (notif) {
        var msgChild;
        var meta = notif.meta;
        var msgData = {
          staticClass: 'v-notification__message col'
        };
        if (notif.html === true) {
          msgData.domProps = {
            innerHTML: notif.caption ? "<div>".concat(notif.message, "</div><div class=\"v-notification__caption\">").concat(notif.caption, "</div>") : notif.message
          };
        } else {
          var msgNode = [notif.message];
          msgChild = notif.caption ? [h('div', msgNode), h('div', {
            staticClass: 'v-notification__caption'
          }, [notif.caption])] : msgNode;
        }
        var mainChild = [];
        if (meta.hasMedia === true) {
          if (notif.spinner !== false) {
            mainChild.push(h(notif.spinner, {
              staticClass: 'v-notification__spinner',
              props: {
                indeterminate: true
              }
            }));
          } else if (notif.icon) {
            mainChild.push(h(VIcon, {
              staticClass: 'v-notification__icon',
              attrs: {
                role: 'img'
              },
              props: {
                name: notif.icon
              }
            }));
          } else if (notif.avatar) {
            mainChild.push(h(VAvatar, {
              props: {
                size: 36
              },
              staticClass: 'v-notification__avatar'
            }, [h('img', {
              attrs: {
                'src': notif.avatar,
                'aria-hidden': 'true'
              }
            })]));
          }
        }
        mainChild.push(h('div', msgData, msgChild));
        var child = [h('div', {
          staticClass: meta.contentClass
        }, mainChild)];
        notif.progress === true && child.push(h('div', {
          key: "".concat(meta.uid, "|p|").concat(meta.badge),
          staticClass: 'v-notification__progress',
          style: meta.progressStyle,
          class: notif.progressClass
        }));
        notif.actions !== undefined && child.push(h('div', {
          staticClass: meta.actionsClass
        }, notif.actions.map(function (action) {
          return h(VBtn, _objectSpread({}, action));
        })));
        meta.badge > 1 && child.push(h('div', {
          key: "".concat(meta.uid, "|").concat(meta.badge),
          staticClass: meta.badgeStaticClass,
          style: notif.badgeStyle,
          class: notif.badgeClass
        }, [meta.badge]));
        return h('div', {
          ref: "notif_".concat(meta.uid),
          key: meta.uid,
          staticClass: meta.staticClass,
          attrs: meta.attrs
        }, [h('div', {
          staticClass: meta.wrapperClass
        }, child)]);
      }));
    }));
  }
};
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.showComponent ? _c('div', {
    class: [_setup.$style.ModalAcceptedLegalDocuments, _defineProperty({}, _setup.$style.hiding, _setup.hidingAnimation)],
    on: {
      "animationend": _setup.onAnimationEnd
    }
  }, [_c('SimpleOverlay', {
    class: _setup.$style.overlay
  }), _vm._v(" "), _c('section', {
    class: [_setup.$style.modalContent, _defineProperty({}, _setup.$style.loading, _setup.loading)]
  }, [_setup.loading ? _c(_setup.SLoader, {
    attrs: {
      "size": "10",
      "unit": "rem"
    }
  }) : [_c('p', {
    class: _setup.$style.description
  }, [_vm._v("\n        " + _vm._s(_setup.description) + "\n      ")]), _vm._v(" "), _c(_setup.RegistrationPolicy, {
    class: _setup.$style.policy,
    attrs: {
      "target": "_self"
    },
    on: {
      "policy-checked": _setup.policyChecked
    }
  }), _vm._v(" "), _c(_setup.SButton, {
    class: _setup.$style.saveButton,
    attrs: {
      "disabled": !_setup.isCheckedPolicy
    },
    on: {
      "click": _setup.onSaveButtonClick
    }
  }, [_vm._v("\n        Продолжить\n      ")])]], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
import type { Role } from '@/store/authStore';

const additionalApi = {
  additional: {
    list: (role: Role) => `/api/agencies/${ role }/additionals`,
    additionalAgreementById: (role: Role, additionalId: string) => `/api/agencies/${ role }/additionals/${ additionalId }`,
    getDocument: (role: Role, additionalId: string) => `/api/agencies/${ role }/additionals/${ additionalId }/get_document`,
  },
};

export default additionalApi;

export const additionalApiConfig = additionalApi.additional;

import {
  defineNuxtPlugin,
  onGlobalSetup,
  useRoute,
} from '@nuxtjs/composition-api';
import type { Context } from '@nuxt/types';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { errorByValueMessage } from '@@/shared/utilites/misc';

let nuxtApp: Context | undefined;
let route: ReturnType<typeof useRoute> | undefined;

export default defineNuxtPlugin((_nuxtApp: Context) => {
  nuxtApp = _nuxtApp;
  onGlobalSetup(() => {
    route = useRoute();
  });
});

export const useVuexStore = (): NonNullable<typeof nuxtApp>['store'] => {
  if (!nuxtApp?.store) {
    throw new Error('Vuex Store is not defined :(');
  }

  return nuxtApp?.store;
};

/**
 * В отличие от useContext, работает даже вне setup контекста
 */
export const useIsomorphicContext = (): Context => nuxtApp!;

export const useAxios = (): NuxtAxiosInstance => {
  if (nuxtApp == null) {
    throw new Error(errorByValueMessage({ nuxtApp }));
  }

  return nuxtApp.$axios;
};

export const useIsomorphicRoute = () => route!;

import Vue from 'vue';
import { SModalPlugin } from '@strana-artw/ui-kit';

import type SModal from '@strana-artw/ui-kit/dist/ui/src/components/SModal/SModal.vue';

Vue.use(SModalPlugin);

// Объявление компонентов для корректной подсветки в IDE
export function initRelatedComponents (): void {
  // noinspection JSCheckFunctionSignatures
  Vue.component('SModal', {} as unknown as typeof SModal);
}

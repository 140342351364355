import {
  type Strategy,
  useAuthStore
} from '@@/lk_broker/store/authStore';
import { toRefs } from 'vue';

export const useAuthStrategy = (): Strategy => {
  const authStore = useAuthStore();
  const { strategy } = toRefs(authStore);

  if (!strategy.value) {
    throw new Error(`Auth strategy is not defined: ${ strategy.value } `);
  }

  return strategy.value;
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderProfileDropdown_8iH1A{background-color:#fafafa;border-radius:.8rem;-webkit-box-shadow:0 .4rem 1.5rem 0 rgba(95,105,131,.122),0 .5rem .4rem 0 rgba(95,105,131,.051),0 0 .4rem 0 rgba(95,105,131,.071);box-shadow:0 .4rem 1.5rem 0 rgba(95,105,131,.122),0 .5rem .4rem 0 rgba(95,105,131,.051),0 0 .4rem 0 rgba(95,105,131,.071);color:#012345;font-size:1.6rem;font-weight:500;line-height:1.56;padding-bottom:1.2rem;padding-top:1.2rem}.HeaderProfileDropdown_8iH1A hr{background-color:#dee2e5;border:none;height:.1rem;margin-bottom:.4rem;margin-top:.4rem}.listItem_oSk38 .listItemContent_mmRXZ{padding-bottom:.4rem;padding-top:.4rem}.listItem_oSk38:last-child{margin-bottom:.4rem}.listItem_oSk38:first-child{margin-top:.4rem}.listItemContent_mmRXZ{display:-webkit-box;display:-ms-flexbox;display:flex;gap:1.2rem;padding-left:1.6rem;padding-right:1.6rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:inherit;font-weight:inherit;line-height:inherit;position:relative;width:100%}.listItemContentClickable_kjQIK:hover{color:#92278f}.listItemContentClickable_kjQIK:hover:before{opacity:.04}.listItemContentClickable_kjQIK:active:before{opacity:.12}.listItemContentClickable_kjQIK:before{background-color:currentColor;bottom:0;content:\"\";left:0;opacity:0;position:absolute;right:0;top:0;z-index:0}.listItemContentClickable_kjQIK:focus-visible{outline:2px solid #4169e1;outline-offset:0}.listItemContentClickable_kjQIK .loyaltyLevelLabel_G-\\+Xh{color:#677b8f;font-size:1rem;font-weight:500;letter-spacing:.01em;line-height:1.46;text-transform:uppercase}.lastItem_2qAxo{color:#92278f;padding-bottom:.8rem;padding-top:.8rem}.inlineVertical_GkSUa{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:.4rem}.customIcon_IvDIb{height:2.4rem;width:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderProfileDropdown": "HeaderProfileDropdown_8iH1A",
	"listItem": "listItem_oSk38",
	"listItemContent": "listItemContent_mmRXZ",
	"listItemContentClickable": "listItemContentClickable_kjQIK",
	"loyaltyLevelLabel": "loyaltyLevelLabel_G-+Xh",
	"lastItem": "lastItem_2qAxo",
	"inlineVertical": "inlineVertical_GkSUa",
	"customIcon": "customIcon_IvDIb"
};
module.exports = ___CSS_LOADER_EXPORT___;
